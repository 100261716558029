import React, { useEffect } from 'react';

import { defineMessages } from 'react-intl';
import { trackEvent } from 'src/analytics/controller';
import { useISIntl } from 'src/i18n';
import { PaymentsOrderListModel } from 'src/models/PaymentsOrderListModel';
import { usePaymentsDashboardEligibility } from 'src/payments/hooks/usePaymentsDashboardEligibility';
import { PaymentEventComponent } from 'src/payments/types';

import { PaymentsDashboardOnboarded, PaymentsDashboardOnboarding } from './Statuses';

const messages = defineMessages({
  setUpPayments: {
    id: 'paymentsDashboard.setUpPayments',
    defaultMessage: 'Set Up Payments'
  },
  finishSetUpPayments: {
    id: 'paymentsDashboard.finishSetUpPayments',
    defaultMessage: 'Finish Set Up'
  }
});

export const PaymentsDashboard = (props: {
  dateFormat: string;
  paymentsOrderList: PaymentsOrderListModel;
  onError: (error: { title?: string; body?: string }) => void;
}) => {
  const { fta } = useISIntl();
  useEffect(() => {
    trackEvent('payments-screen', {
      screenAction: 'shown',
      screen: 'payments-dashboard'
    });
  }, []);

  const paymentsDashboardEligibility = usePaymentsDashboardEligibility();

  if (paymentsDashboardEligibility.eligible.length === 0) {
    return null;
  }

  if (paymentsDashboardEligibility.accepting.length > 0) {
    return <PaymentsDashboardOnboarded {...props} />;
  }

  if (paymentsDashboardEligibility.pending.length > 0) {
    return (
      <PaymentsDashboardOnboarding
        ctaButtonText={fta(messages.finishSetUpPayments)}
        component={PaymentEventComponent.tilePending}
      />
    );
  }

  if (paymentsDashboardEligibility.initial.length > 0) {
    return (
      <PaymentsDashboardOnboarding
        ctaButtonText={fta(messages.setUpPayments)}
        component={PaymentEventComponent.tileInitial}
      />
    );
  }

  return null;
};
