import React from 'react';

import { getIntlCurrency, SubscriptionTier } from '@invoice-simple/common';

import { useISIntl } from 'src/i18n';
import UserModel, { Cadence } from 'src/models/UserModel';
import { defaultsStore } from 'src/stores/DefaultsStore';
import { getPaywallCouponInfo, getSavingsText } from '../utils';
import { messages } from '../utils/messages';
import { SubscriptionBadge } from './SubscriptionBadge';

interface Props {
  originalPriceDisplay: number;
  discountPriceDisplay: number;
  tier: SubscriptionTier;
  cadence: Cadence;
}

export const DiscountedPrice = ({
  originalPriceDisplay,
  discountPriceDisplay,
  tier,
  cadence
}: Props) => {
  const user = UserModel.getInstance();
  const { formatMessage } = useISIntl();
  const couponInfo = getPaywallCouponInfo();
  if (!couponInfo) {
    return null;
  }

  const getPeriodicity = (cadence?: Cadence) => {
    return cadence === Cadence.ANNUAL ? '/yr' : '/mo';
  };

  return (
    <span className="flex justify-start items-baseline md:items-start md:flex-col mt-3 mb-3 md:mb-0 ml-[30px] md:ml-0 gap-2.5">
      <span className="flex flex-row font-medium text-gray-800 line-through text-3xl md:text-xl">
        {getIntlCurrency({
          number: originalPriceDisplay,
          currencyCode: user.geoSubCurrencyCode,
          localeCode: defaultsStore.locale.name
        })}
        <span className="hidden md:block">{getPeriodicity(cadence)}</span>
      </span>
      <span
        className="font-extrabold text-gray-900 mt-2"
        style={{ fontSize: 28, lineHeight: '30px' }}>
        {getIntlCurrency({
          number: discountPriceDisplay,
          currencyCode: user.geoSubCurrencyCode,
          localeCode: defaultsStore.locale.name
        })}
        <span className="text-xl font-medium text-gray-800 pl-2">{getPeriodicity(cadence)}</span>
      </span>
      <SubscriptionBadge
        text={formatMessage(messages.discountedPaywallBadge, {
          discount: getSavingsText(couponInfo, tier, cadence)
        })}
        variant="green"
      />
    </span>
  );
};
