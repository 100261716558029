import { PaypalAccountSettings } from '@invoice-simple/is-paypal-sdk';
import qs from 'qs';

import {
  AccountOrdersPaginated,
  CaptureStatus,
  PaypalOrderFormatted
} from '../components/PaymentsDashboard';
import { invokeIsPaypal } from './apis/paypal.api';
import { invokeIsStripe } from './apis/stripe.api';
import { StripeAccountSettings } from '@invoice-simple/is-stripe-sdk';

const paypalApiV1Endpoint = '/api/v1';
const stripeApiV1Endpoint = '/api/v1';

export async function getPaypalAccountStatus(): Promise<PaypalAccountSettings> {
  return invokeIsPaypal('GET', `${paypalApiV1Endpoint}/accounts/me`);
}

export async function paypalEnable(): Promise<PaypalAccountSettings> {
  return invokeIsPaypal('PUT', `${paypalApiV1Endpoint}/accounts/me/enable`);
}

export async function paypalDisable(): Promise<PaypalAccountSettings> {
  return invokeIsPaypal('PUT', `${paypalApiV1Endpoint}/accounts/me/disable`);
}

export async function getStripeAccountStatus(): Promise<StripeAccountSettings> {
  return invokeIsStripe('GET', `${stripeApiV1Endpoint}/accounts/me`);
}

export async function stripeEnable(): Promise<StripeAccountSettings> {
  return invokeIsStripe('PATCH', `${stripeApiV1Endpoint}/accounts/me/enable`);
}

export async function stripeDisable(): Promise<StripeAccountSettings> {
  return invokeIsStripe('PATCH', `${stripeApiV1Endpoint}/accounts/me/disable`);
}

export async function stripePaymentsConnectClientSecret(): Promise<{ client_secret: string }> {
  return invokeIsStripe<{ client_secret: string }>(
    'GET',
    `${stripeApiV1Endpoint}/accounts/me/stripe-account-session`
  );
}

export async function getPaymentsOrders({
  sort = '-createdAt',
  page,
  limit,
  captureStatus = 'ALL'
}: {
  sort?: keyof PaypalOrderFormatted | `-${keyof PaypalOrderFormatted}`;
  captureStatus?: CaptureStatus | CaptureStatus[] | 'ALL';
  page?: number;
  limit?: number;
}): Promise<AccountOrdersPaginated> {
  const searchParams = qs.stringify({
    captureStatus:
      Array.isArray(captureStatus) || captureStatus === 'ALL' ? captureStatus : [captureStatus],
    sort,
    page: page,
    size: limit
  });

  return invokeIsPaypal('GET', `${paypalApiV1Endpoint}/accounts/me/orders?${searchParams}`);
}
