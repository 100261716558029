import React, { useEffect, useState, useContext, createContext, ReactNode, FC } from 'react';
import {
  StripeAccountSettings,
  isStripeEligible,
  isStripeInitial,
  isStripePending,
  isStripePendingVerification,
  isStripeAccepting,
  isStripeEnabled,
  isStripeAcceptingAndEnabled,
  StripeFees
} from '@invoice-simple/is-stripe-sdk';
import { StripeModal } from '../components/IntegratedPayments';
import { getStripeAccountStatus, stripeDisable, stripeEnable } from '../utils/Accounts';
import { onError } from '../utils/utils';
import { OnboardOrigin } from '../types';
import { usePaymentOnboardModal } from '../hooks/usePaymentsOnboardModal';

interface StripeAccountContextType {
  stripeStatus?: StripeAccountSettings;
  stripeMerchantId?: string | null;
  stripeCountry?: string | null;
  stripePrimaryCurrency?: string | null;
  stripePrimaryEmail?: string | null;
  isStripeInitial: boolean;
  isStripePending: boolean;
  isStripeEligible: boolean;
  isStripePendingVerification: boolean;
  isStripeAccepting: boolean;
  isStripeEnabled: boolean;
  isStripeAcceptingAndEnabled: boolean;
  getStripeFees: StripeFees | null;

  isStripeModalOpen: boolean;
  showStripeModal: () => void;
  hideStripeModal: () => void;
  stripeModalOrigin: OnboardOrigin;
  updateStripeEnabled: (enable: boolean) => Promise<void>;
}

const StripeAccountContext = createContext<StripeAccountContextType | null>(null);

export const StripeAccountProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [stripeStatus, setStripeStatus] = useState<StripeAccountSettings>();
  const { isOpen, showModal, hideModal, origin } = usePaymentOnboardModal();

  const updateStripeStatus = async (): Promise<void> => {
    try {
      setStripeStatus(await getStripeAccountStatus());
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    updateStripeStatus().then(() => {
      window.addEventListener('focus', updateStripeStatus);
    });
    return () => {
      window.removeEventListener('focus', updateStripeStatus);
    };
  }, []);

  const updateStripeEnabled = async (enable: boolean) => {
    let stripeAccountStatus: StripeAccountSettings;
    try {
      stripeAccountStatus = await (enable ? stripeEnable() : stripeDisable());
      setStripeStatus(stripeAccountStatus);
    } catch (error) {
      onError({
        title: 'Error updating Stripe account',
        body: 'Please try again later.'
      });
      updateStripeStatus();
    }
  };

  return (
    <StripeAccountContext.Provider
      value={{
        stripeStatus,
        stripeMerchantId: stripeStatus?.stripe.stripeMerchantId,
        stripeCountry: stripeStatus?.stripe.stripeCountry,
        stripePrimaryCurrency: stripeStatus?.stripe.stripePrimaryCurrency,
        stripePrimaryEmail: stripeStatus?.stripe.stripePrimaryEmail,

        isStripeEligible: stripeStatus ? isStripeEligible(stripeStatus) : false,
        isStripeInitial: stripeStatus ? isStripeInitial(stripeStatus) : false,
        isStripePending: stripeStatus ? isStripePending(stripeStatus) : false,
        isStripePendingVerification: stripeStatus
          ? isStripePendingVerification(stripeStatus)
          : false,
        isStripeAccepting: stripeStatus ? isStripeAccepting(stripeStatus) : false,
        isStripeEnabled: stripeStatus ? isStripeEnabled(stripeStatus) : false,
        isStripeAcceptingAndEnabled: stripeStatus
          ? isStripeAcceptingAndEnabled(stripeStatus)
          : false,
        getStripeFees:
          stripeStatus && isStripeAccepting(stripeStatus) ? stripeStatus.stripe.stripeFees : null,

        isStripeModalOpen: isOpen,
        showStripeModal: showModal,
        hideStripeModal: hideModal,
        stripeModalOrigin: origin,
        updateStripeEnabled
      }}>
      {isOpen && <StripeModal closeModal={hideModal} origin={origin} />}
      {children}
    </StripeAccountContext.Provider>
  );
};

export const useStripeContext = (): StripeAccountContextType | null =>
  useContext(StripeAccountContext);
