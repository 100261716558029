import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { SubscriptionTier } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';

import { ISIntl } from 'src/i18n';
import { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { titleizeAll } from 'src/util/Titleize';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { LoadingSpinner } from '../Button';
import { PlanIntervalSelector } from './components';
import { BackButton } from './components/BackButton';
import { PaywallTitle } from './components/PaywallTitle';
import { Testimonials } from './components/Testimonials';
import { TieredMatrixListV2 } from './components/TieredMatrixListV2';
import { ValueProps } from './components/ValueProps';
import { useInterval } from './hooks/useInterval';
import { useUpgrade } from './hooks/useUpgrade';
import {
  getIntervalText,
  getPaywallVariant,
  getReasonForUpgrade,
  getTierRequired,
  maybeShowAlertBanner,
  orderedTiers
} from './utils';
import { messages } from './utils/messages';

export type PaywallVariant =
  | 'default'
  | 'essentials-to-plus'
  | 'essentials-to-premium'
  | 'plus-to-premium'
  | 'premium-legacy';

interface Props {
  store: AppStore;
  intl: ISIntl;
}

export const SubscriptionTieredV2 = ({ store, intl }: Props) => {
  const { user } = store;
  const { formatMessage, ft, fta } = intl;

  const feature = new URLSearchParams(window.location.search).get('feature') as FeatureName;
  const tierRequired = getTierRequired(getReasonForUpgrade(feature, user));
  const currentTier = user.activeSubscriptionTier;
  const paywallVariant = getPaywallVariant(currentTier, tierRequired);
  const { selectedInterval, toggleInterval, shouldDisallowSwitch } = useInterval(
    user.activeSubscriptionCadence
  );

  const defaultTabState = tierRequired === SubscriptionTier.PREMIUM ? 2 : 1;

  const tabState = useState(defaultTabState);
  const [selectedMobileTab, setSelectedMobileTab] = tabState;

  useEffect(() => {
    maybeShowAlertBanner(store, selectedInterval, intl);
  }, []);

  const { switching, loadingCheckout, onUpgradeClick, OriginalPrice, FinalPrice, isCurrentPlan } =
    useUpgrade({
      store,
      intl,
      selectedInterval,
      shouldDisallowSwitch
    });

  const loading = loadingCheckout || switching;

  const getButtonText = (tier: SubscriptionTier) => {
    if (isCurrentPlan(tier)) {
      return fta(messages.currentActivePlan);
    }

    if (user.isSubPaid && paywallVariant !== 'default') {
      return ft(messages.upgrade);
    }

    if (getURLQueryParam(URLQueryParamKeys.REF) === 'settings') {
      return ft(messages.switch);
    }

    return ft(messages.upgrade);
  };

  const intervalText = getIntervalText(selectedInterval);

  return (
    <div id="tailwind">
      <div
        className="relative mx-auto sm:py-24 px-4 sm:px-6 lg:px-8"
        style={{ maxWidth: '1000px' }}>
        <BackButton />
        <div className="sm:align-center sm:flex sm:flex-col">
          <div className="md:max-w-[600px] text-5xl font-bold text-gray-900 sm:text-center my-0 mx-auto">
            <img
              data-testid="is-logo"
              className="h-24 sm:h-28 w-auto rounded"
              src="images/is-logo.png"
              alt="Invoice Simple Logo"
            />
            <PaywallTitle paywallVariant={paywallVariant} />
            <ValueProps paywallVariant={paywallVariant} />
          </div>

          <Testimonials isHidden={paywallVariant !== 'default'} />

          <div className="mt-[40px] sm:mt-[60px] mx-auto">
            <h3
              className="text-center font-semibold mx-auto mb-[10px] sm:max-w-[500px] tracking-normal text-gray-900"
              style={{ fontSize: 20, lineHeight: '28px' }}>
              {ft(messages.tableTopTitle)}
            </h3>
            <p
              className="text-center font-normal text-gray-900"
              style={{ fontSize: 14, lineHeight: '20px' }}>
              {ft(messages.tableTopSubtitle)}
            </p>
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="bg-white pt-[10px] sm:align-center sm:flex sm:flex-col sticky top-0">
            <PlanIntervalSelector
              selectedInterval={selectedInterval}
              onToggleInterval={toggleInterval}
              formatMessage={formatMessage}
              isHidden={user.hasAnnualSub && paywallVariant !== 'default'}
            />

            <div className="hidden md:block mt-[20px] mx-[25px]">
              <table className="h-px w-full table-fixed">
                <caption className="sr-only">Pricing plan comparison</caption>
                <thead className="border-bottom border-gray-200">
                  <tr>
                    <th className="pb-4 text-left text-2xl font-medium text-gray-900" scope="col">
                      <span className="sr-only">Feature by</span>
                      <span>{ft(messages.tieredPlans)}</span>
                    </th>
                    {orderedTiers.map((tier, i0) => {
                      const spacingClasses = {
                        'pr-[20px]': i0 === 0,
                        'pl-[20px]': i0 === orderedTiers.length - 1,
                        'px-[10px]': i0 > 0 && i0 < orderedTiers.length - 1
                      };
                      return (
                        <th
                          key={tier}
                          className="w-1/4 pb-4 text-left text-2xl font-medium text-gray-900"
                          scope="col">
                          <span className={classNames(spacingClasses)}>{titleizeAll(tier)}</span>
                          {tier === SubscriptionTier.PLUS && paywallVariant === 'default' && (
                            <span
                              style={{
                                backgroundColor: 'rgba(69, 94, 222, 0.1)',
                                color: 'rgba(69, 94, 222, 1)',
                                fontWeight: 700,
                                lineHeight: '22px',
                                fontSize: 12,
                                cursor: 'default',
                                fontFamily:
                                  'Roboto, system-ui, -apple-system, Segoe UI, Ubuntu, Cantarell, Noto Sans, sans-serif'
                              }}
                              className="inline-block rounded-full py-1 px-4 ml-3 uppercase">
                              {fta(messages.mostPopular)}
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 border-t border-gray-200">
                  <tr>
                    <th
                      className="py-8 text-left align-top text-xl font-medium text-gray-900"
                      scope="row">
                      {ft(messages.tieredPricing)}
                    </th>
                    {orderedTiers.map((tier, i0) => {
                      const spacingClasses = {
                        'pr-[20px]': i0 === 0,
                        'pl-[20px]': i0 === orderedTiers.length - 1,
                        'px-[10px]': i0 > 0 && i0 < orderedTiers.length - 1
                      };

                      const shouldHideCrossGradeTier =
                        paywallVariant !== 'default' &&
                        user.activeSubscriptionTier === tier &&
                        user.activeSubscriptionCadence !== selectedInterval;

                      const shouldHideTier =
                        (paywallVariant !== 'default' &&
                          tierRequired === 'premium' &&
                          currentTier &&
                          tierRequired !== tier &&
                          !isCurrentPlan(tier)) ||
                        shouldHideCrossGradeTier;

                      const tierPrice = (
                        <p className={classNames('mb-10', spacingClasses)}>
                          <span className="text-4xl font-extrabold tracking-tight text-gray-900">
                            <span className="font-medium">
                              {selectedInterval === Cadence.ANNUAL && <OriginalPrice tier={tier} />}
                            </span>
                            <FinalPrice tier={tier} isTieredMatrix={true} />
                          </span>{' '}
                          <span className="text-xl font-medium text-gray-500">{intervalText}</span>
                        </p>
                      );

                      if (shouldHideTier) {
                        return (
                          <td
                            key={tier}
                            data-testid={`${tier}-tier-details`}
                            className="h-full py-8 align-top">
                            {tierPrice}
                          </td>
                        );
                      }

                      return (
                        <td
                          key={tier}
                          data-testid={`${tier}-tier-details`}
                          className="h-full py-8 align-top">
                          {tierPrice}
                          <div className={classNames(spacingClasses)}>
                            <button
                              type="button"
                              onClick={() => onUpgradeClick(tier)}
                              data-testid={`btn-upgrade-tier-${tier}`}
                              disabled={
                                loading || shouldDisallowSwitch(tier, user) || isCurrentPlan(tier)
                              }
                              className={`no-underline block w-full flex-grow rounded-[4px] transition ${
                                isCurrentPlan(tier)
                                  ? 'bg-gray-100 text-gray-900 border border-solid border-gray-200'
                                  : 'bg-orange-is text-white border border-orange-is border-solid'
                              } py-3 text-center text-xl font-semibold hover:opacity-70 ${
                                loading || shouldDisallowSwitch(tier, user)
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}>
                              {loading && <LoadingSpinner loadingTextStyle="text-white" />}
                              {getButtonText(tier)}
                            </button>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="lg:hidden mt-[20px] tierTabs grid grid-cols-3">
              {orderedTiers.map((tier, index) => (
                <div key={tier}>
                  <button
                    style={{
                      fontSize: 16,
                      ...(index === selectedMobileTab
                        ? {
                            color: '#455EDE',
                            borderBottomWidth: 0,
                            borderTopWidth: 1,
                            borderLeftWidth: 1,
                            borderRightWidth: 1
                          }
                        : {
                            borderBottomWidth: 1,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                            borderRightWidth: 0
                          })
                    }}
                    onClick={() => setSelectedMobileTab(index)}
                    className="w-full h-20 bg-white py-[15px] rounded-t-lg border-gray-200 border-solid">
                    {titleizeAll(tier)}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <TieredMatrixListV2
            store={store}
            intl={intl}
            selectedInterval={selectedInterval}
            shouldDisallowSwitch={shouldDisallowSwitch}
            showTabState={tabState}
            tierRequired={tierRequired}
          />
        </div>
      </div>
    </div>
  );
};
