import { useFlags } from 'flagsmith/react';

import { usePaypalContext } from '../contexts/PaypalAccountContext';
import { useStripeContext } from '../contexts/StripeAccountContext';
import { PaymentsProviderName } from '../types';
import { useIsPaymentsLegacyUser } from './useIsPaymentsLegacyUser';

export interface PaymentsDashboardEligibility {
  eligible: PaymentsProviderName[];
  initial: PaymentsProviderName[];
  pending: PaymentsProviderName[];
  accepting: PaymentsProviderName[];
  paymentsTabHref: string | null;
}

export const usePaymentsDashboardEligibility = (): PaymentsDashboardEligibility => {
  const { payments_enabled, new_payments_dashboard } = useFlags([
    'payments_enabled',
    'new_payments_dashboard'
  ]);
  const paypalAccountContext = usePaypalContext();
  const stripeAccountContext = useStripeContext();
  const isLegacyUser = useIsPaymentsLegacyUser();

  const defaultEligibility: PaymentsDashboardEligibility = {
    eligible: [],
    initial: [],
    pending: [],
    accepting: [],
    paymentsTabHref: null
  };

  if (!payments_enabled?.enabled || (!new_payments_dashboard?.enabled && !isLegacyUser)) {
    return defaultEligibility;
  }

  if (!stripeAccountContext?.stripeStatus || !paypalAccountContext?.paypalStatus) {
    return defaultEligibility;
  }

  const {
    isStripeEligible,
    isStripeAccepting,
    isStripePending,
    isStripePendingVerification,
    isStripeInitial
  } = stripeAccountContext;

  const {
    isPaypalEligible,
    isPaypalAccepting,
    isPaypalPending,
    isPaypalInitial,
    isPaypalOnboarded,
    isPaypalClosed
  } = paypalAccountContext;

  if (!isPaypalEligible() && !isStripeEligible) {
    return defaultEligibility;
  }

  const paypalProvider = {
    provider: PaymentsProviderName.PAYPAL,
    isEligible: isPaypalEligible(),
    isAccepting: isPaypalAccepting() || isPaypalClosed(),
    isPending: isPaypalPending() || isPaypalOnboarded(),
    isInitial: isPaypalInitial()
  };

  if (isLegacyUser) {
    const { isInitial, isPending, isAccepting, isEligible } = paypalProvider;

    if (!isEligible) {
      return defaultEligibility;
    }

    let paymentsTabHref = '/paypal/learnmore';

    if (isAccepting) {
      paymentsTabHref = '/payments/dashboard';
    }
    return {
      initial: isInitial ? [PaymentsProviderName.PAYPAL] : [],
      eligible: isEligible ? [PaymentsProviderName.PAYPAL] : [],
      pending: isPending ? [PaymentsProviderName.PAYPAL] : [],
      accepting: isAccepting ? [PaymentsProviderName.PAYPAL] : [],
      paymentsTabHref
    };
  }

  const paymentProviderMap = [
    paypalProvider,
    {
      provider: PaymentsProviderName.STRIPE,
      isEligible: isStripeEligible,
      isAccepting: isStripeAccepting,
      isPending: isStripePending || isStripePendingVerification,
      isInitial: isStripeInitial
    }
  ];

  return {
    ...paymentProviderMap.reduce(
      (acc, { provider, isEligible, isAccepting, isPending, isInitial }) => {
        if (isEligible) {
          acc.eligible.push(provider);
        }
        if (isAccepting) {
          acc.accepting.push(provider);
        }
        if (isPending) {
          acc.pending.push(provider);
        }
        if (isInitial) {
          acc.initial.push(provider);
        }

        return acc;
      },
      defaultEligibility
    ),
    paymentsTabHref: '/payments/dashboard'
  };
};
