import { addDays, differenceInCalendarDays } from 'date-fns';
import { flatten } from 'flat';
import { DebouncedFunc } from 'lodash';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import defaultsDeep from 'lodash/defaultsDeep';
import includes from 'lodash/includes';
import omit from 'lodash/omit';
import { action, computed, IReactionDisposer, Lambda, observable, observe, reaction } from 'mobx';
import Parse from 'parse';
import { v1 as uuid } from 'uuid';

import {
  getInvoiceBalance,
  getInvoiceNonZeroItemsCount,
  getInvoiceTax,
  getInvoiceTotal,
  getItemRateWithPaymentFees,
  getSubtotal,
  isDepositFlatAmount
} from '@invoice-simple/calculator';
import {
  Deposit,
  DepositAmounts,
  DepositRates,
  DepositTypes,
  DiscountType,
  DiscountTypes,
  DocType,
  DocTypes,
  InvoiceCompany,
  InvoicePayment,
  InvoicePhoto,
  InvoiceSetting,
  InvoiceTerm,
  InvoiceTermTypes,
  PassingFeesType,
  PaymentInstructions,
  Platform,
  SettingKeys,
  TaxType,
  TaxTypes,
  UniversalInvoice,
  ZERO
} from '@invoice-simple/common';
import { EstimateSettings, Item } from '@invoice-simple/domain-invoicing';
import { PaypalFees } from '@invoice-simple/is-paypal-sdk';

import { trackGoogleFirstInvoiceCreated } from 'src/analytics/google';
import { getPhoto, getPhotos } from 'src/apis/photosAPI';
import { getPdfURL } from 'src/controllers/pdfController';
// data
import { hasTerm } from 'src/i18n/intlTermOptions';
// stores
import environmentStore from 'src/stores/EnvironmentStore';
import { dateToString, stringToDate } from 'src/util/date';
import { isMigratedEditorEnabled } from 'src/util/isMigratedEditorEnabled';
// util
import { Invoice as ParseInvoice } from 'src/util/IsParseDomain';
import { navFreeTrial } from 'src/util/navigation';
import { URLQueryParamKeys } from 'src/util/url';
import { trackFacebookEvent } from '../analytics/facebook';
// API
import * as invoiceAPI from '../apis/docsAPI';
import colorOptions from '../data/colorOptions';
// types
import { InvoiceExportAction, InvoiceSentStatus } from '../types/Invoice';
import diffObject from '../util/DiffObject';
// models
import alertModel from './AlertModel';
import ClientModel from './ClientModel';
import InvoiceClientModel from './InvoiceClientModel';
import InvoiceEmailModel from './InvoiceEmailModel';
import InvoiceItemModel from './InvoiceItemModel';
import InvoicePaymentModel from './InvoicePaymentModel';
import InvoicePhotoModel from './InvoicePhotoModel';
import location from './LocationModel';
import PhotoModel from './PhotoModel';
import SyncableEntity from './SyncableEntity';
import { UniversalInvoiceModel } from './Universalnvoice';
import UserModel from './UserModel';

export class InvoiceModel extends SyncableEntity {
  debounceSave: DebouncedFunc<() => Promise<Parse.Object>> = debounce(
    this.save,
    environmentStore.debounceRate
  );
  disposeSyncEmailReaction?: IReactionDisposer;
  invoiceCreatedReaction: IReactionDisposer;

  universalModel: UniversalInvoiceModel;

  // Invoice other field
  balanceDue: number;
  account: any;

  // Funnel observer
  isCompleteObserver: Lambda;
  hasCompleted: boolean = false;

  // parent
  @observable user: UserModel;

  @observable title: string;
  @observable draftEmail: InvoiceEmailModel;

  // save
  @observable autoSave?: Lambda = undefined;

  // parse
  @observable remoteId: string = uuid();
  @observable invoiceNo: string;
  @observable invoiceDate: string;
  @observable dueDate?: string;
  @observable cachePaidDate: string;
  @observable cacheTotal: number;
  @observable docType: DocType;
  @observable setting: InvoiceSetting;
  @observable company: InvoiceCompany;
  @observable accountId: string;
  @observable createdAt: Date;
  @observable updatedAt: Date;
  @observable items: InvoiceItemModel[];
  @observable client: InvoiceClientModel;
  @observable payments: InvoicePaymentModel[];
  @observable draftPayment: InvoicePaymentModel;
  @observable logo: PhotoModel;
  @observable invoiceLogo?: string;
  @observable invoicePaymentInstructions: PaymentInstructions;
  @observable settingCurrencyCode: string;
  @observable companyBusinessLabel?: string;
  @observable platform?: Platform;
  @observable signDate?: string; // YYYY-MM-DD
  @observable signature?: string; // signature photo remoteId
  @observable signaturePhotoUrl?: string;
  @observable poNumber?: string;

  photos: InvoicePhoto[] = [];
  @observable invoicePhotos: InvoicePhotoModel[] = [];

  // controls markup payment fees animations
  @observable isMarkingUpPaymentFees: boolean = false;

  // pass back to parse-server to meet validation requirements
  // no user interaction
  defaultToPaid: boolean = false;
  sentStatus: InvoiceSentStatus;

  private hasInvoiceBeenCounted: boolean = false;

  constructor(user: UserModel, data: Parse.Object) {
    super(ParseInvoice, invoiceAPI, 'invoice');
    this.universalModel = new UniversalInvoiceModel({ invoice: this });
    if (user) {
      this.user = user;
      this.logo = user.settingList && user.settingList.logo;
    }
    this.draftPayment = new InvoicePaymentModel(this, {});
    this.client = InvoiceClientModel.createFromInvoiceData(this, data.get('client'));
    this.update(data);
    this.initDraftEmail();
    this.invoiceCreatedReaction = reaction(
      () => this.id,
      (id) => {
        if (typeof id !== 'undefined') {
          this.user.events.trackAction('invoice-create', {
            invoiceId: id,
            'doc-type': this.docType,
            platform: Platform.WEB,
            color: this.color,
            template: this.setting.template,
            'request-signature':
              this.docType === DocTypes.DOCTYPE_ESTIMATE
                ? !!this.setting.estimateSignatureRequired
                : undefined
          });
          trackFacebookEvent('CreateInvoice', {
            userId: this.user.id,
            ...this.user.abTests
          });
        }
      },
      {
        name: 'invoice-create-reaction'
      }
    );

    this.isCompleteObserver = observe(this, 'isComplete', (change) => {
      if (!this.hasCompleted && change.object.value && this.user && location.isEdit) {
        this.hasCompleted = true;

        this.user.handleInvoicesCompleted();
        this.user.trackAppEventViaApi('invoice-complete', {
          'invoice-id': this.id,
          'remote-id': this.remoteId,
          'invoice-no': this.invoiceNo,
          'doc-type': `${this.docType}`,
          platform: Platform.WEB
        });

        const fbOpts = {
          userId: this.user.id,
          ...this.user.abTests
        };
        trackFacebookEvent('CompleteInvoice', fbOpts);
        trackFacebookEvent('Lead', fbOpts);
        this.user.trackAdwords('web-invoice-complete');

        if (this.user.docsCount === 1) {
          trackGoogleFirstInvoiceCreated();
        }
      }
    });
  }

  static async createFromClient(user: UserModel, client: ClientModel, data: any = {}) {
    const invoiceTitle = user.settingList.getSetting(SettingKeys.RenameInvoiceTitle) ?? 'Invoice';
    const estimateTitle =
      user.settingList.getSetting(SettingKeys.RenameEstimateTitle) ?? 'Estimate';

    const _defaults = {
      title: data.docType === DocTypes.DOCTYPE_ESTIMATE ? estimateTitle : invoiceTitle
    };

    const invoiceDefaults = user.settingList.getDocumentsDefaults(data.docType);

    const invoice = new InvoiceModel(
      user,
      new ParseInvoice({ ..._defaults, ...invoiceDefaults, ...data })
    );
    invoice.setClient(client);

    await invoice.save();
    return invoice;
  }

  @computed get asUniversal(): UniversalInvoice {
    return this.universalModel.value;
  }

  @computed
  get logoUrl() {
    return this.user.settingList.logo.url || this.invoiceLogo;
  }

  @computed
  get paymentInstructions(): PaymentInstructions {
    return this.user.settingList.paymentInstructions || this.invoicePaymentInstructions;
  }

  // currency
  @computed
  get currencyCode(): string | undefined {
    return this.settingCurrencyCode || this.user.settingList.currencyCode;
  }

  @computed
  get isIdValid(): boolean {
    return !!this.id && !includes(['id', 'undefined'], this.id) && this.id.length === 10;
  }

  @computed
  get publicPath() {
    return this.isIdValid ? `/v/${this.id}` : undefined;
  }
  @computed
  get publicUrl(): string | undefined {
    return this.isIdValid ? [environmentStore.appUrl, this.publicPath].join('') : undefined;
  }
  @computed
  get isSignedEstimate(): boolean {
    return this.isEstimate && !!(this.setting as EstimateSettings).estimateSignedAt;
  }

  // parse cache data, ignored by client, prefer calculated from payments etc
  @action
  public update(data: Parse.Object | any) {
    this.id = data.id;
    this.cachePaidDate = this._parse(data, 'paidDate') as string;
    this.cacheTotal = this._parse(data, 'total') as number;
    this.deleted = this._parse(data, 'deleted') as boolean;
    this.id = this._parse(data, 'id') as string;
    this.title = this._parse(data, 'title') as string;
    this.remoteId = this._parse(data, 'remoteId') as string;
    this.invoiceNo = this._parse(data, 'invoiceNo') as string;
    this.invoiceDate = this._parse(data, 'invoiceDate') as string;
    this.dueDate = this._parse(data, 'dueDate') as string | undefined;
    this.docType = this._parse(data, 'docType') as DocType;
    this.setting = this._parse(data, 'setting') as unknown as InvoiceSetting;
    this.company = this._parse(data, 'company') as InvoiceCompany;
    this.accountId = (this._parse(data, 'account') as unknown as Parse.Object).id;
    this.platform = this._parse(data, 'platform') as Platform;
    this.signDate = this._parse(data, 'signDate') as string;
    this.signature = this._parse(data, 'signature') as string;
    this.createdAt = this._parse(data, 'createdAt') as Date;
    this.updatedAt = this._parse(data, 'updatedAt') as Date;
    this.items = (this._parse(data, 'items') as Item[]).map((i) => new InvoiceItemModel(this, i));
    this.payments = (this._parse(data, 'payments') as InvoicePayment[]).map(
      (i) => new InvoicePaymentModel(this, i)
    );
    this.poNumber = this._parse(data, 'poNumber') as string;

    // new validation requirements
    this.photos = this._parse(data, 'photos') as InvoicePhoto[];
    this.sentStatus = this._parse(data, 'sentStatus') as InvoiceSentStatus;
    this.defaultToPaid = this._parse(data, 'defaultToPaid') as boolean;

    this._fixLegacyFields();

    // set hasComplete from current loaded state
    // to avoid 2nd isComplete event trigger
    this.hasCompleted = this.isComplete;
    if (this.shouldSetSurchargeFeesTypeByDefault()) {
      this.setFeesType(PassingFeesType.SURCHARGE);
    }
  }

  @computed
  get visibleItems() {
    return this.items.filter((i) => !!i.isVisible);
  }

  @computed
  get visiblePayments() {
    return this.payments.filter((i) => i.deleted === false);
  }

  @computed
  get isInvoice() {
    return this.docType === DocTypes.DOCTYPE_INVOICE;
  }
  @computed
  get isEstimate() {
    return this.docType === DocTypes.DOCTYPE_ESTIMATE;
  }
  @computed
  get isReceipt() {
    return this.docType === DocTypes.DOCTYPE_STATEMENT;
  }

  @computed
  get isTaxNone() {
    return this.setting.taxType === TaxTypes.TAX_NONE;
  }
  @computed
  get isTaxItems() {
    return this.setting.taxType === TaxTypes.TAX_ITEM;
  }
  @computed
  get isTaxTotal() {
    return this.setting.taxType === TaxTypes.TAX_SUBTOTAL;
  }
  @computed
  get isTaxDeducted() {
    return this.setting.taxType === TaxTypes.TAX_DEDUCTED;
  }

  @computed
  get isDiscountPercent() {
    return this.setting.discountType === DiscountTypes.DISCOUNT_PERCENTAGE;
  }
  @computed
  get isDiscountFlat() {
    return this.setting.discountType === DiscountTypes.DISCOUNT_FLAT_AMOUNT;
  }

  @computed
  get businessNumber() {
    return !!this.company && this.company.businessNumber;
  }
  @computed
  get businessName() {
    return !!this.company && this.company.company;
  }
  @computed
  get businessOwnerName() {
    return !!this.company && this.company.name;
  }
  @computed
  get businessWebsite() {
    return !!this.company && this.company.website;
  }

  // helpers
  @computed
  get companyAddress(): string {
    return compact([this.company.address1, this.company.address2, this.company.address3]).join(
      ', '
    );
  }

  @computed
  get taxLabel() {
    return this.setting.taxLabel;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      remoteId: this.remoteId,
      invoiceNo: this.invoiceNo,
      poNumber: this.poNumber,
      docType: this.docType,
      dueDate: this.dueDate,
      invoiceDate: this.invoiceDate,
      setting: this.setting,
      company: this.company,
      client: this.client.asInvoiceData,
      items: this.visibleItems.map((i) => i.parseData),
      payments: this.visiblePayments.map((i) => i.parseData),
      deleted: this.deleted,
      createDate: this.isNew ? new Date() : undefined,
      platform: this.platform,
      signDate: this.signDate,
      signature: this.signature,
      photos: this.photos
    };
  }
  // data saved to parse
  @computed
  get parseData() {
    return {
      id: this.id,
      title: this.title,
      remoteId: this.remoteId,
      poNumber: this.poNumber,
      invoiceNo: this.invoiceNo,
      docType: this.docType,
      dueDate: this.dueDate,
      invoiceDate: this.invoiceDate,
      setting: this.setting,
      company: this.company,
      client: this.client.asInvoiceData,
      items: this.visibleItems.map((i) => i.parseData),
      payments: this.visiblePayments.map((i) => i.parseData),
      deleted: this.deleted,
      createDate: this.isNew ? new Date() : undefined,
      platform: this.platform,
      signDate: this.signDate,
      signature: this.signature,

      // required for other systems (mails etc.)
      taxAmount: +getInvoiceTax(this.asUniversal),
      subTotal: +getSubtotal(this.asUniversal),
      total: +getInvoiceTotal(this.asUniversal),
      balanceDue: +getInvoiceBalance(this.asUniversal),
      paidDate: this.getInvoicePaidDate(),

      // required for parser-server validation
      photos: this.photos,
      sentStatus: this.sentStatus
    };
  }

  //
  // autoSaveData
  // save on change to user editable data
  //
  @computed
  get autoSaveData(): { invoiceNo: string } {
    return flatten({
      title: this.title,
      invoiceNo: this.invoiceNo,
      dueDate: this.dueDate,
      invoiceDate: this.invoiceDate,
      setting: omit(this.setting, ['remoteId']),
      company: omit(this.company, ['remoteId']),
      client: this.client.autoSaveData,
      items: this.visibleItems.map((i) => i.autoSaveData),
      payments: this.visiblePayments.map((i) => i.autoSaveData),
      photos: this.photos,
      poNumber: this.poNumber
    });
  }

  @computed
  get isComplete() {
    return (
      getInvoiceTotal(this.asUniversal).gt(ZERO) &&
      (!!this.company.email || !!this.businessName) &&
      (!!this.client.email || !!this.client.name)
    );
  }
  @computed
  get isValid(): boolean {
    return !!this.invoiceNo && this.invoiceNo.length > 0;
  }

  getInvoiceDate(): Date {
    return stringToDate(this.invoiceDate);
  }

  getInvoicePaidDate(): string | undefined {
    const paymentsCount = !!this.visiblePayments && this.visiblePayments.length;
    if (paymentsCount > 0) {
      return dateToString(this.visiblePayments[paymentsCount - 1].date);
    }
    return undefined;
  }

  @action.bound
  setPoNumber(value: string): void {
    this.poNumber = value;
    this.user.events.trackAction('invoice-set-field', {
      field: 'poNumber',
      value
    });
  }

  @action.bound
  setInvoiceDate(date: Date): void {
    this.invoiceDate = dateToString(date);
    if (this.setting.termsDay !== undefined && this.setting.termsDay > 0) {
      this.dueDate = dateToString(addDays(stringToDate(this.invoiceDate), this.setting.termsDay));
    }
    this.user.trackAppEventViaApi('invoice-set-field', {
      field: 'invoiceDate',
      value: this.invoiceDate
    });
  }

  setTerms(value: InvoiceTerm) {
    this.setting.termsDay = value;
    switch (value) {
      case InvoiceTermTypes.NONE:
        this.dueDate = undefined;
        break;
      case InvoiceTermTypes.CUSTOM:
        if (!this.dueDate) {
          this.dueDate = dateToString(new Date());
        }
        break;
      case InvoiceTermTypes.DUE_ON_RECEIPT:
        this.dueDate = undefined;
        break;
      default:
        this.dueDate = dateToString(addDays(stringToDate(this.invoiceDate), this.setting.termsDay));
    }
    this.user.trackAppEventViaApi('invoice-set-field', {
      field: 'setting.termsDay',
      value: this.setting.termsDay
    });
  }

  public setDueDate(date: Date): void {
    this.dueDate = dateToString(date);
    const days = differenceInCalendarDays(
      stringToDate(this.dueDate),
      stringToDate(this.invoiceDate)
    );
    if (days > 0) {
      this.setTerms(hasTerm(days) ? (days as InvoiceTerm) : InvoiceTermTypes.CUSTOM);
    } else {
      this.setTerms(InvoiceTermTypes.CUSTOM);
    }
    this.user.trackAppEventViaApi('invoice-set-field', {
      field: 'dueDate',
      value: this.dueDate
    });
  }

  public getDueDate(): Date {
    if (!this.dueDate) {
      return new Date();
    }
    return stringToDate(this.dueDate);
  }

  @computed
  get hasDueDate(): boolean {
    return !!this.dueDate;
  }

  @computed
  get balance(): ReturnType<typeof getInvoiceBalance> {
    return getInvoiceBalance(this.asUniversal);
  }

  // comments
  public getComments(): string | undefined {
    return this.setting.comment;
  }
  public setComments(value: string): void {
    this.setting.comment = value;
  }

  public get paymentSuppressed(): boolean | undefined {
    return this.setting && this.setting.paymentSuppressed;
  }

  public get stripePaymentSuppressed(): boolean | undefined {
    return this.setting && this.setting.stripePaymentSuppressed;
  }

  private onPaymentSuppressedChange = () => {
    if (!this.paymentSuppressed && !this.stripePaymentSuppressed) {
      return;
    }

    this.isMarkingUpPaymentFees = false;
    if (this.payments.length <= 0) {
      this.setting.depositRate = undefined;
    }
  };

  public setPaymentSuppressed(value: boolean): void {
    this.setting.paymentSuppressed = value;
    this.user.trackAppEventViaApi('invoice-set-field', {
      field: 'setting.paymentSuppressed',
      value: this.setting.paymentSuppressed
    });
    this.onPaymentSuppressedChange();
  }

  public setStripePaymentSuppressed(value: boolean): void {
    this.setting.stripePaymentSuppressed = value;
    this.onPaymentSuppressedChange();
  }

  public setFeesType(value: PassingFeesType): void {
    this.setting.feesType = value;
  }

  // color
  @computed
  get defaultColor(): string {
    return colorOptions[0];
  }
  @computed
  get color(): string | undefined {
    return this.setting && this.setting.color;
  }

  public setColor(value: string): void {
    this.setting.color = value;

    this.user.trackAppEventViaApi('invoice-set-field', {
      field: 'setting.color',
      value: this.setting.color
    });
  }

  public setEstimateSignatureRequired(value: boolean): void {
    this.setting.estimateSignatureRequired = value;
  }

  public setTaxType(value: TaxType): void {
    this.setting.taxType = value;
    if (value === TaxTypes.TAX_NONE) {
      this.setting.taxRate = 0;
    }
    this.user.trackAppEventViaApi('invoice-set-field', {
      field: 'setting.taxType',
      value: this.setting.taxType
    });
  }

  public setTaxLabel(value: string): void {
    this.setting.taxLabel = value;
  }

  public setDiscountType(value: DiscountType): void {
    this.setting.discountType = value;
    switch (value) {
      case DiscountTypes.DISCOUNT_NONE:
        this.setting.discountAmount = 0;
        this.setting.discountRate = 0;
        break;
      case DiscountTypes.DISCOUNT_PERCENTAGE:
        this.setting.discountAmount = 0;
        break;
      case DiscountTypes.DISCOUNT_FLAT_AMOUNT:
        this.setting.discountRate = 0;
        break;
    }
    this.user.trackAppEventViaApi('invoice-set-field', {
      field: 'setting.discountType',
      value: this.setting.discountType
    });
  }
  @action
  public setCompanyPhone(value: string) {
    this.company.phone = value;
  }
  @action
  public setClientPhone(value: string) {
    this.client.phone = value;
  }
  @action.bound
  public setCompanyEmail(value: string): void {
    const _value = value.trim();
    this.company.email = _value;
    this.user.initEmail(_value);
  }
  @action.bound
  public setClientEmail(value: string): void {
    this.client.email = value.trim();
  }
  @action
  public setTaxInclusive(value: boolean) {
    this.setting.taxInclusive = value;
    this.user.trackAppEventViaApi('invoice-set-field', {
      field: 'setting.taxInclusive',
      value: this.setting.taxInclusive
    });
  }
  @action
  public setDiscountAmount(value: number) {
    this.setting.discountAmount = value;
  }
  @action
  public setCompanyName(value: string) {
    this.company.company = value;
  }
  @action
  public setBusinessNumber(value: string) {
    this.company.businessNumber = value;
  }
  @action
  public setBusinessWebsite(value: string) {
    this.company.website = value;
  }
  @action
  public setBusinessOwner(value: string) {
    this.company.name = value;
    this.company.businessName = value;
  }

  public setDiscountRate(value: number) {
    if (typeof value !== 'number' || isNaN(value)) {
      value = 0;
    }
    if (value >= 0 && value <= 100) {
      this.setting.discountRate = value;
    }
  }
  public resetClient(): void {
    this.client = InvoiceClientModel.createFromInvoiceData(this, {});
  }

  @action.bound
  public setClient(clientModel: ClientModel) {
    this.client = InvoiceClientModel.createFromClientModel(this, clientModel);
  }

  //
  // items
  //
  @action.bound
  public addItem(itemDefaults = {}): void {
    this.items.push(new InvoiceItemModel(this, itemDefaults));
    this.user.trackAppEventViaApi('invoice-item-add', { platform: Platform.WEB });
  }

  //
  // email
  //
  @action.bound
  public initDraftEmail(): void {
    this.draftEmail = new InvoiceEmailModel(this);
    if (this.disposeSyncEmailReaction) {
      this.disposeSyncEmailReaction();
    }
    this.disposeSyncEmailReaction = reaction(
      () => ({
        toEmail: this.client.email,
        fromEmail: this.company.email || ''
      }),
      ({ fromEmail, toEmail }) => {
        this.draftEmail.setFromEmail(fromEmail);
        this.draftEmail.setToEmail(toEmail);
      },
      {
        fireImmediately: true,
        name: 'Update email draft from invoice'
      }
    );
  }

  //
  // payments
  //
  @action.bound
  public addPayment(payment: InvoicePaymentModel): void {
    this.payments.push(payment);
  }

  @action.bound
  public async markPaid(paymentMethod: string) {
    this.initDraftPayment(paymentMethod);
    this.addDraftPayment();
    return await this.save();
  }

  @action.bound
  public async markUnpaid() {
    this.payments = [];
    return await this.save();
  }

  @action.bound
  public addDraftPayment(): void {
    this.addPayment(this.draftPayment);
    this.initDraftPayment();
  }

  @action.bound
  public initDraftPayment(paymentMethod?: string): void {
    this.draftPayment = new InvoicePaymentModel(this, {
      amount: +getInvoiceBalance(this.asUniversal),
      date: new Date(),
      paymentMethod
    });
  }

  // auto save
  @action.bound
  public startAutoSave() {
    if (this.autoSave) {
      return;
    }
    this.autoSave = observe(this, 'autoSaveData', (change) => {
      // only save on user change
      if (change && change.oldValue && change.oldValue.invoiceNo) {
        if (isDepositFlatAmount(this.asUniversal)) {
          this.rejectInvalidDepositAmount();
        }

        // sync settings
        debounce(() => {
          if (!change || !change.oldValue) return;

          const diff = diffObject(change.oldValue, change.newValue);

          if (location.isEdit) {
            this.user.settingList.updateFromInvoice(this.docType, diff);
          }
        }, environmentStore.debounceRate);

        this.debounceSave();
      }
    });
  }

  @action.bound
  print(): void {
    this.user.events.trackAction('invoice-print', {
      invoiceId: this.id,
      'doc-type': this.docType,
      docCount: this.user.allDocsCount
    });
    if (this.user.canExportDocument(InvoiceExportAction.PRINT)) {
      window.printUrl(`/v/${this.id}?viewType=mobile`);
    } else {
      navFreeTrial({ user: this.user, ref: URLQueryParamKeys.DOCUMENT_ACTIONS });
    }
  }
  @action.bound
  public showEditForm(): void {
    this.user.trackAppEventViaApi('invoice-edit-nav');
    location.navAndScrollTop(`${location.canonicalDocName}Edit`, { id: this.id });
  }
  @action.bound
  public showPaymentForm(): void {
    this.user.trackAppEventViaApi('invoice-payment-nav');
    location.navAndScrollTop(`${location.canonicalDocName}Payment`, { id: this.id });
  }
  @action.bound
  public async showPreview(): Promise<void> {
    if (this.isNew) {
      await this.save();
    }
    this.user.trackAppEventViaApi('invoice-preview-nav');
    location.navAndScrollTop(`${location.canonicalDocName}View`, { id: this.id });
  }

  @action.bound
  public async showEmailForm(message?: string): Promise<void> {
    if (this.isNew) {
      await this.save();
    }
    this.user.trackAppEventViaApi('invoice-email-nav');

    const canSendDoc = await this.user.canExportDocument(InvoiceExportAction.EMAIL);
    if (this.user.isSubActive || (!this.user.isGuest && canSendDoc)) {
      if (isMigratedEditorEnabled(this.user.countryCode)) {
        const docTypePath = this.docType === DocTypes.DOCTYPE_INVOICE ? 'invoices' : 'estimates';
        return window.location.assign(
          `${process.env.REACT_APP_URL}/${docTypePath}/${this.id}?email=true`
        );
      }
      location.navAndScrollTop(`${location.canonicalDocName}Email`, { id: this.id, message });
      return;
    }
    navFreeTrial({
      user: this.user,
      ref: URLQueryParamKeys.SEND_DOCUMENT,
      documentId: this.id,
      docType: this.docType
    });
  }

  @action.bound
  public showHistory(): void {
    location.navAndScrollTop(`${location.canonicalDocName}History`, {
      id: this.id
    });
  }

  @action.bound
  public async sendEmail(): Promise<void> {
    if (this.isNew) {
      await this.save();
    }
    return this.draftEmail.send();
  }

  @action.bound
  public async download(): Promise<void> {
    if (this.isNew) {
      await this.save();
    }
    const pdfUrl = getPdfURL(this);
    if (pdfUrl) {
      this.user.events.trackAction('invoice-download', {
        'doc-type': this.docType,
        docCount: this.user.allDocsCount,
        platform: Platform.WEB
      });

      window.location.assign(pdfUrl);
    }
  }

  @action.bound
  public async openPublicLink(): Promise<void> {
    if (this.isNew) {
      await this.save();
    }
    this.user.events.trackAction('invoice-link', {
      'doc-type': this.docType,
      docCount: this.user.allDocsCount,
      platform: Platform.WEB
    });
    if (this.user.canExportDocument(InvoiceExportAction.GET_LINK)) {
      if (this.publicUrl) {
        const win = window.open(this.publicUrl, '_blank');
        if (win) {
          win.focus();
        }
      }
    } else {
      navFreeTrial({ user: this.user, ref: URLQueryParamKeys.DOCUMENT_ACTIONS });
    }
  }

  public async save(): Promise<Parse.Object> {
    await this.loadTransaction;
    this._fixBeforeSave();
    const savePromise = super.save();

    if (this.isNew) {
      if (!this.hasInvoiceBeenCounted && this.docType !== DocTypes.DOCTYPE_ESTIMATE) {
        this.user.incDocsCount();
        this.hasInvoiceBeenCounted = true;
      }
      if (this.docType === DocTypes.DOCTYPE_INVOICE || this.docType === DocTypes.DOCTYPE_ESTIMATE) {
        this.user.incInvoiceEstimatesCount();
      }
      savePromise.then(() => {
        this.user.settingList.syncLastInvoiceNo(this.docType);
      });
    }

    savePromise.then((saved: Parse.Object) => {
      if (saved.get('deleted')) {
        this.user.settingList.syncLastInvoiceNo(this.docType);
      }
    });

    return savePromise;
  }

  @action
  public async convertEstimate(): Promise<Parse.Object> {
    if (!this.isEstimate) {
      return Promise.reject(new Error('Estimate required to convert to Invoice'));
    }

    const markAsFullyPaidAndSave = async () => {
      this.setting.fullyPaid = true;
      await this.save();
    };

    const _defaults = {
      title: 'Invoice'
    };

    const invoiceTitle = this.user.settingList.getSetting(SettingKeys.RenameInvoiceTitle);
    if (invoiceTitle && invoiceTitle.value) {
      _defaults.title = invoiceTitle.value;
    }

    const convertedEstimateData = await this._convertEstimateData();
    const invoiceDefaults = this.user.settingList.getDocumentsDefaults(0) as Partial<
      typeof convertedEstimateData
    >;

    const estimateComment = convertedEstimateData.setting.comment;
    const defaultEstimateComment = this.user.settingList.getSettingValueAsString(
      SettingKeys.DefaultEstimateNote
    );
    // If we have a comment in the estimate notes and its not the default note
    if (estimateComment && estimateComment !== defaultEstimateComment) {
      const invoiceWithComment = new InvoiceModel(
        this.user,
        new ParseInvoice({
          ..._defaults,
          ...invoiceDefaults,
          ...convertedEstimateData
        })
      );

      await markAsFullyPaidAndSave();
      return invoiceWithComment.save();
    }

    // If we don't have a comment in estimate notes, use default from invoice settings.
    // This allows us to merge the settings when parsing the invoice so that
    // discount and tax rate set in the estimate carry over.
    const mergedSettings = {
      ...convertedEstimateData.setting,
      ...invoiceDefaults.setting
    };

    const invoice = new InvoiceModel(
      this.user,
      new ParseInvoice({
        ..._defaults,
        ...convertedEstimateData,
        ...invoiceDefaults,
        setting: mergedSettings
      })
    );

    await markAsFullyPaidAndSave();
    return invoice.save();
  }

  @action
  public async saveSignature({
    signaturePhotoRemoteId,
    signaturePhotoUrl,
    signDate
  }: {
    signaturePhotoRemoteId: string;
    signaturePhotoUrl: string;
    signDate: string;
  }) {
    this.signature = signaturePhotoRemoteId;
    this.signaturePhotoUrl = signaturePhotoUrl;
    this.signDate = signDate;
    await this.save();
  }

  @action
  public async deleteSignature() {
    this.signature = '';
    this.signDate = '';
    await this.save();
  }

  public async load(): Promise<void> {
    if (this.isLoading || environmentStore.isSnapshot()) {
      return Promise.resolve();
    }
    if (!this.id || this.id === '') {
      throw new Error('Cannot load document without id');
    }
    try {
      const invoice = await this.loadPrivate();
      await this.loadInvoicePhotos().catch(() => {
        alertModel.showFailedToLoadPhotosAlert();
      });
      this.client = InvoiceClientModel.createFromInvoiceData(this, invoice.get('client'));
    } catch (error) {
      // handle doc missing && deleted
      if (error.code === 101 || error.code === 1009) {
        location.nav(`${location.canonicalDocName}List`);
        return alertModel.showDocMissingAlert();
      }

      this.user.handleError('invoice-load', error);
    }
  }

  @action.bound
  public async markDeleted() {
    await this.load();
    this.deleted = true;
    await this.save();
    location.nav(`${location.canonicalDocName}List`);
  }

  @action.bound
  public async markUndeleted() {
    await this.load();
    this.deleted = false;
    await this.save();
    location.nav(`${location.canonicalDocName}List`);
  }

  @action.bound
  public async deleteInvoicePhoto(remoteId: string) {
    this.photos = this.photos.filter((photo) => photo.photoRemoteId !== remoteId);
    await this.save();
  }

  @action
  public async loadInvoicePhotos() {
    const remotePhotos = await getPhotos(this.photos.map((p) => p.photoRemoteId));
    const mergedPhotoArray = this.photos.map((invoicePhoto) => {
      const remoteInvoicePhoto = remotePhotos.find(
        (remotePhoto) => remotePhoto.attributes.remoteId === invoicePhoto.photoRemoteId
      )?.attributes;

      const photoData = {
        ...invoicePhoto,
        ...remoteInvoicePhoto
      };

      return new InvoicePhotoModel(this, photoData);
    });
    this.invoicePhotos = mergedPhotoArray;
    if (this.signature) {
      const sigPhoto = await getPhoto(this.signature);
      this.signaturePhotoUrl = sigPhoto?.attributes.url || 'no-url';
    }
  }

  @action
  private async loadPrivate(): Promise<Parse.Object> {
    const invoice = await super.load();
    this.initDraftPayment();
    return invoice;
  }

  @action
  public markupPaymentFees(paymentFees: PaypalFees) {
    this.isMarkingUpPaymentFees = true;
    const invoice = this.asUniversal;
    try {
      const invoiceTotalBeforeCalc = +getInvoiceTotal(invoice);
      this.items.forEach((item) => {
        const rateWithPaymentFees = +getItemRateWithPaymentFees({
          invoice,
          item,
          paymentFees
        });
        item.setRate(rateWithPaymentFees);
      });
      const invoiceTotalAfterCalc = +getInvoiceTotal(this.asUniversal);

      this.user.trackAppEventViaApi('paypal-invoice-passingFeesClicked', {
        invoiceId: this.id,
        invoiceNumItems: getInvoiceNonZeroItemsCount(invoice),
        currencyCode: this.currencyCode,
        invoiceTotal: invoiceTotalBeforeCalc,
        invoiceTotalMutated: invoiceTotalAfterCalc
      });

      setTimeout(() => {
        this.isMarkingUpPaymentFees = false;
      }, 10000);
    } catch (error) {
      this.isMarkingUpPaymentFees = false;
      alertModel.setAlert('danger', 'Unable to markup payment fees', error.message);
    }
  }

  @action
  public updatePaymentDeposit({ depositRate, depositType, depositAmount }: Deposit) {
    const {
      depositRate: originalRate = DepositRates.DEPOSIT_NONE,
      depositAmount: originalAmount = DepositAmounts.DEPOSIT_NONE
    } = this.setting;
    this.setting.depositType = depositType || undefined;
    switch (depositType) {
      case DepositTypes.NONE:
        this.setting.depositRate = undefined;
        this.setting.depositAmount = undefined;
        break;
      case DepositTypes.PERCENT:
        this.setting.depositRate = depositRate || undefined;
        this.setting.depositAmount = undefined;
        break;
      case DepositTypes.FLAT:
        this.setting.depositAmount = depositAmount || undefined;
        this.setting.depositRate = undefined;
    }
    const {
      depositRate: updatedRate = DepositRates.DEPOSIT_NONE,
      depositAmount: updatedAmount = DepositAmounts.DEPOSIT_NONE
    } = this.setting;

    this.user.trackAppEventViaApi('payments-webDepositRate-updated', {
      invoiceId: this.id,
      type: depositType,
      originalRate,
      originalAmount,
      updatedRate,
      updatedAmount
    });
  }

  @action
  public rejectInvalidDepositAmount() {
    const { depositAmount: originalAmount = DepositAmounts.DEPOSIT_NONE } = this.setting;
    const invoiceTotal = +getInvoiceTotal(this.asUniversal);
    if (!originalAmount || originalAmount >= invoiceTotal || originalAmount < 0) {
      this.setting.depositAmount = undefined;
      this.user.trackAppEventViaApi('payments-webDepositRate-updated', {
        invoiceId: this.id,
        type: DepositTypes.NONE,
        originalRate: DepositRates.DEPOSIT_NONE,
        originalAmount,
        updatedRate: DepositRates.DEPOSIT_NONE,
        updatedAmount: DepositAmounts.DEPOSIT_NONE
      });
    }
  }

  @action
  private async _clonePhotos() {
    if (this.invoicePhotos.length === 0) await this.loadInvoicePhotos();

    const cloned = await Promise.all(
      this.invoicePhotos.map(async (photo) => {
        const photoData = {
          remoteId: photo.parseData.remoteId,
          url: photo.parseData.url,
          md5: photo.parseData.md5
        };
        const newPhoto = new PhotoModel(this.user, () => {}, photoData);
        const newPhotoRemoteId = await newPhoto.duplicatePhoto();
        if (newPhotoRemoteId) {
          return {
            name: photo.name,
            description: photo.description,
            photoRemoteId: newPhotoRemoteId
          };
        }
        return;
      })
    );

    return cloned.filter((photo) => !!photo);
  }

  //
  // make invoice data
  // ensure that new invoice has new remoteId for setting, company, photos, client and items
  //
  @action.bound
  private async _convertEstimateData() {
    const setRemoteId = <T>(obj: T): T => {
      return { ...obj, ...{ remoteId: uuid() } };
    };

    const clonedPhotos = await this._clonePhotos();

    return {
      docType: DocTypes.DOCTYPE_INVOICE,
      setting: { ...setRemoteId(this.setting), estimateId: this.remoteId },
      company: setRemoteId(this.company),
      client: setRemoteId(this.client.asInvoiceData),
      items: this.visibleItems.map((i) => setRemoteId(i.parseData)),
      photos: clonedPhotos.map((p) => setRemoteId(p)),
      updatedAt: new Date()
    };
  }

  // This is to repopulate the correct fields to if an older invoice is rendered or modified
  private _fixLegacyFields() {
    if (!this.company.company) {
      this.company.company = this.company.name;
    }
    if (this.company.ownerName) {
      this.company.name = this.company.ownerName;
      this.company.ownerName = undefined;
    }
  }

  // Fix potential issues before saving to avoid relying on Parse server ALWAYS_FIX logic
  private _fixBeforeSave() {
    if (
      typeof this.sentStatus?.updatedAt === 'string' &&
      this.sentStatus.updatedAt.split('T').length === 2
    ) {
      this.sentStatus.updatedAt = new Date(this.sentStatus.updatedAt);
    }
  }

  //
  // _parse
  // - attempt value as parse object get
  // - attempt value as object
  // - attempt deep merge with defaults if object
  // - return value or default
  // - only assign default for new invoices
  private _parse(raw: any, name: string, assignDefault: boolean = true) {
    let v = null;
    const d = this._default(name, !raw.id);
    if (typeof raw.get === 'function') {
      v = raw.get(name);
    }
    if (name in raw) {
      v = raw[name];
    }
    if (assignDefault && v && typeof v === 'object') {
      defaultsDeep(v, d);
    }
    return v == null ? d : v;
  }

  private _default(name: string, isNew: boolean = true) {
    const now = new Date();
    return {
      deleted: false,
      remoteId: uuid(),
      invoiceDate: dateToString(now),
      dueDate: undefined,
      paidDate: undefined,
      docType: location.docType,
      company: {
        remoteId: uuid(),
        company: '',
        name: '',
        email: '',
        address1: '',
        address2: '',
        address3: '',
        phone: '',
        businessNumber: '',
        website: ''
      },
      setting: {
        remoteId: uuid(),
        fullyPaid: false,
        taxInclusive: false,
        taxLabel: undefined,
        color: this.defaultColor,
        taxType: TaxTypes.TAX_SUBTOTAL,
        taxRate: 0,
        discountType: DiscountTypes.DISCOUNT_NONE,
        discountRate: 0,
        discountAmount: 0,
        depositRate: undefined,
        depositType: undefined,
        depositAmount: undefined,
        comment: '',
        termsDay:
          this.docType === DocTypes.DOCTYPE_ESTIMATE ? undefined : InvoiceTermTypes.DUE_ON_RECEIPT,
        paymentSuppressed: undefined,
        feesType: undefined,
        stripePaymentSuppressed: undefined
      },
      total: 0,
      subTotal: 0,
      balanceDue: 0,
      account: {},
      items: isNew ? [new InvoiceItemModel(this, {})] : [],
      payments: [],
      photos: [],
      sentStatus: {
        name: 'draft',
        updatedAt: now
      }
    }[name];
  }

  private shouldSetSurchargeFeesTypeByDefault(): boolean {
    return (!this.id &&
      this.user.settingList.getSetting(SettingKeys.PaymentFeesType) &&
      this.user.settingList.getSetting(SettingKeys.PaymentFeesType)?.valNum ===
        PassingFeesType.SURCHARGE &&
      this.user.settingList.getSetting(SettingKeys.PaymentAlwaysAddSurcharge) &&
      this.user.settingList.getSetting(SettingKeys.PaymentAlwaysAddSurcharge)?.valBool) as boolean;
  }
}

export default InvoiceModel;
