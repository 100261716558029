import React from 'react';

import { Platform, SubscriptionTier } from '@invoice-simple/common';
import { getMaxCouponDiscount, PaywallCouponConfig } from '@invoice-simple/is-coupon';

import { ISIntl } from 'src/i18n';
import { Cadence } from 'src/models/UserModel';
import { getAnnualDiscount } from '../utils';
import { messages } from '../utils/messages';
import { PlanIntervalButton } from './PlanIntervalButton';

interface PlanIntervalSelectorV2Props {
  selectedInterval: Cadence;
  onToggleInterval: (interval: Cadence) => void;
  formatMessage: ISIntl['formatMessage'];
  isHidden?: boolean;
  couponInfo?: PaywallCouponConfig;
  className?: string;
}

export const PlanIntervalSelectorV2 = ({
  selectedInterval,
  onToggleInterval,
  formatMessage,
  isHidden,
  couponInfo,
  className
}: PlanIntervalSelectorV2Props) => {
  if (isHidden) {
    return null;
  }

  const badgeText = () => {
    if (couponInfo) {
      const discount = getMaxCouponDiscount({
        paywallCouponConfig: couponInfo,
        platform: Platform.WEB
      });
      return formatMessage(messages.badgeUpToDiscount, { discount });
    }

    const discountPercentage = getAnnualDiscount(SubscriptionTier.PREMIUM);

    return formatMessage(messages.discountedPaywallBadge, { discount: discountPercentage });
  };

  return (
    <div className={`relative flex justify-center ${className}`}>
      <div data-testid="interval-select" className="flex w-fit rounded-lg bg-slate-100">
        <PlanIntervalButton
          className="rounded-l-lg"
          interval={Cadence.MONTHLY}
          isSelected={Cadence.MONTHLY === selectedInterval}
          onClick={() => onToggleInterval(Cadence.MONTHLY)}
          buttonText={formatMessage(messages.monthly)}
        />
        <PlanIntervalButton
          className="rounded-r-lg"
          interval={Cadence.ANNUAL}
          isSelected={Cadence.ANNUAL === selectedInterval}
          onClick={() => onToggleInterval(Cadence.ANNUAL)}
          buttonText={formatMessage(messages.annual)}
          badgeText={badgeText()}
        />
      </div>
    </div>
  );
};
