import React, { useState } from 'react';

import { defineMessages } from 'react-intl';
import { useISIntl } from 'src/i18n';
import UserModel from 'src/models/UserModel';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventComponent } from 'src/payments/types';
import { getPaymentEventSource, redirectToPaymentsLandingPageWithEvents } from 'src/payments/utils';

import { BackgroundGradient } from './BackgroundGradient';
import { FeatureBox } from './FeatureBox';

const messages = defineMessages({
  getPaidFaster: {
    id: 'paymentsDashboard.getPaidFaster',
    defaultMessage: 'Get Paid Faster'
  },
  receivePayments: {
    id: 'paymentsDashboard.receivePayments',
    defaultMessage:
      'Invoice Simple Payments is a hassle-free way to receive payments from your clients.'
  },
  securePayments: {
    id: 'paymentsDashboard.securePayments',
    defaultMessage: 'Secure & fraud-protected payments'
  },
  lowFees: {
    id: 'paymentsDashboard.lowFees',
    defaultMessage: 'Low or no Fees'
  },
  lookPro: {
    id: 'paymentsDashboard.lookPro',
    defaultMessage: 'Look like a pro'
  },
  clickToPay: {
    id: 'paymentsDashboard.clickToPay',
    defaultMessage: 'Click-to-pay invoices'
  },
  freeToSetup: {
    id: 'paymentsDashboard.freeSetup',
    defaultMessage: 'Free to setup'
  }
});

export const PaymentsDashboardOnboarding: React.FC<{
  ctaButtonText: string;
  component: PaymentEventComponent;
}> = ({ ctaButtonText, component }) => {
  const [error, setError] = useState<string | null>();
  const { fta } = useISIntl();
  const arrayOfFeatures = [
    fta(messages.securePayments),
    fta(messages.lowFees),
    fta(messages.lookPro),
    fta(messages.clickToPay),
    fta(messages.freeToSetup)
  ];

  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    component
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, UserModel.getInstance().events);

  return (
    <div id="tailwind" data-testid="PaymentsDashboardOnboarding">
      <div className="w-full overflow-hidden relative mt-[-32px]">
        <div className="absolute overflow-hidden top-0 left-0 -z-10">
          <BackgroundGradient className="w-screen h-4/6 lg:h-2/6 min-h-[16rem] max-h-[48rem]" />
        </div>
        <div className="flex flex-col w-screen h-fit mt-6 md:mt-20 justify-center gap-6 z-10 items-center">
          <img
            src="/images/payments/checkout-example-desktop.png"
            alt="Payments Onboarding"
            className="hidden md:block w-8/12 xl:w-5/12 h-5/12 xl:h-3/12 object-cover rounded-xl shadow-lg drop-shadow-md"
          />
          <img
            src="/images/payments/checkout-example-mobile.png"
            alt="Payments Onboarding"
            className="block md:hidden w-2/3 sm:w-5/12 h-2/6 object-cover rounded-xl shadow-lg drop-shadow-md"
          />
          <div className="flex flex-wrap justify-center gap-2 m-4">
            {arrayOfFeatures.map((featureText, index) => (
              <FeatureBox key={index} featureText={featureText} />
            ))}
          </div>
          <div className="text-center flex flex-col gap-8 max-w-lg">
            <div className="text-4xl font-semibold leading-7">{fta(messages.getPaidFaster)}</div>
            <div className="text-2xl leading-8">{fta(messages.receivePayments)}</div>
          </div>
          <button
            onClick={async () => {
              try {
                await redirectToPaymentsLandingPageWithEvents();
              } catch (error) {
                setError(error.message);
              }
            }}
            className="bg-orange-is px-5 py-4 border-none text-white rounded-xl font-bold text-2xl">
            {ctaButtonText}
          </button>
          {error && <div className="text-red-500">{error}</div>}
        </div>
      </div>
    </div>
  );
};
