import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { ISIntl } from 'src/i18n';
import { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { useUpgrade } from '../hooks/useUpgrade';
import { getPaywallCouponInfo } from '../utils';
import { messages } from '../utils/messages';
import { DiscountedPrice } from './DiscountedPrice';
import { SubscriptionButton } from './SubscriptionButton';

interface DiscountMobilePriceProps {
  store: AppStore;
  intl: ISIntl;
  tier: SubscriptionTier;
  selectedInterval: Cadence;
}

export const DiscountMobilePrice: React.FC<DiscountMobilePriceProps> = (props) => {
  const { store, intl, tier, selectedInterval } = props;
  const { ft, fta } = intl;
  const { loadingCheckout, onBuyClick, getCouponPrices } = useUpgrade({
    store,
    intl,
    selectedInterval
  });

  const couponInfo = getPaywallCouponInfo();
  if (!couponInfo) {
    return null;
  }

  const { originalPriceDisplay, discountPriceDisplay } = getCouponPrices(
    couponInfo,
    tier,
    selectedInterval
  );

  return (
    <div className="mb-8">
      {tier === SubscriptionTier.PLUS && (
        <span
          style={{
            backgroundColor: 'rgba(69, 94, 222, 0.1)',
            color: 'rgba(69, 94, 222, 1)',
            fontWeight: 700,
            lineHeight: '20px',
            fontSize: 12,
            cursor: 'default',
            fontFamily:
              'Roboto, system-ui, -apple-system, Segoe UI, Ubuntu, Cantarell, Noto Sans, sans-serif'
          }}
          className="inline-block rounded-full py-1 px-4 mx-[25px] uppercase">
          {fta(messages.mostPopular)}
        </span>
      )}
      <DiscountedPrice
        originalPriceDisplay={originalPriceDisplay}
        discountPriceDisplay={discountPriceDisplay}
        tier={tier}
        cadence={selectedInterval}
      />
      <div className="mx-[25px]">
        <SubscriptionButton
          text={ft(messages.discountedPaywallBuyNow)}
          tier={tier}
          cadence={selectedInterval}
          loading={loadingCheckout}
          onClick={onBuyClick}
        />
      </div>
    </div>
  );
};
