import { Invoice as InvoiceViewer, Dictionary, ViewType } from '@invoice-simple/invoice-viewer';
import '@invoice-simple/invoice-viewer/dist/index.css';
import React from 'react';
import { observer } from 'mobx-react';
import TextareaAutosize from 'react-textarea-autosize';

import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';
import localeStore from 'src/stores/LocaleStore';
import { WhenEditingInvoice, WhenInvoice, WhenViewingInvoice } from '../conditionalRenderer';
import Divider from '../Divider';
import InvoiceItemList from '../InvoiceItemsList/InvoiceItemList';
import InvoiceItemsListMobileEdit from '../InvoiceItemsList/InvoiceItemsListMobileEdit';
import InvoicePhotos from '../InvoicePhotos/InvoicePhotos';
import { Desktop, Mobile } from '../Responsive';
import InvoiceCredentials from './InvoiceCredentials';
import InvoiceTerms from './InvoiceTerms';
import InvoiceTitle from './InvoiceTitle';
import PaymentInstructions from './PaymentInstructions';
import TotalAmounts from './TotalAmounts';
import { usePaymentsQRPlaceholder } from 'src/payments';
import { InvoiceSignature } from './InvoiceSignature';
import { InvoicePayments } from './InvoicePayments';

const messages = defineMessages({
  notesHeader: {
    id: 'invoice.notes.section.header',
    defaultMessage: 'Notes'
  },
  notesHint: {
    id: 'invoice.notesHint',
    defaultMessage: 'notes - any relevant information not covered, additional terms and conditions'
  }
});

const InvoiceBody = observer((props: { store: AppStore }) => {
  const intl = useISIntl();
  const store = props.store;
  const invoice = props.store.doc;
  const isLoading = store.isLoading;

  return (
    <div className="invoice-container">
      {invoice.color && (
        <div className="invoice-detail-accent" style={{ backgroundColor: invoice.color }} />
      )}

      <div className="invoice-detail-body">
        <InvoiceTitle store={store} />
        <InvoiceCredentials store={store} />

        <Mobile>
          <InvoiceItemsListMobileEdit />

          <Divider lite margin />

          <TotalAmounts />

          <Divider lite margin />

          <div className="content-block">
            <InvoicePayments store={store} />
          </div>

          <Divider lite margin />

          <InvoiceTerms store={store} />
        </Mobile>

        <Desktop>
          <Divider lite margin />
          <InvoiceTerms store={store} />
          <InvoiceItemList />
          <Divider margin />
          <TotalAmounts />
        </Desktop>

        <WhenInvoice>
          <PaymentInstructions paymentInstructions={invoice.paymentInstructions} />
        </WhenInvoice>

        <div className="content-block">
          <div className="invoice-detail-notes clearfix">
            <h3 className="invoice-notes-header">{intl.ft(messages.notesHeader)}</h3>
            <TextareaAutosize
              disabled={isLoading}
              id="invoice-notes"
              className="invoice-notes-input"
              minRows={5}
              maxRows={20}
              placeholder={intl.ft(messages.notesHint)}
              value={invoice.getComments()}
              onChange={(e) => invoice.setComments(e.target.value)}
            />
          </div>
        </div>

        <div className="content-block">
          <div className="invoice-detail-signature clearfix">
            <InvoiceSignature invoice={invoice} />
          </div>
        </div>

        <InvoicePhotos store={store} photos={store.doc.invoicePhotos} />
      </div>
    </div>
  );
});

const Wrapper = (props: { store: AppStore }) => {
  const { currencyCode, asUniversal, paymentSuppressed, stripePaymentSuppressed } = props.store.doc;
  const placeholder = usePaymentsQRPlaceholder({
    currencyCode,
    paymentSuppressed,
    stripePaymentSuppressed
  });
  return (
    <>
      <WhenEditingInvoice>
        <InvoiceBody {...props} />
      </WhenEditingInvoice>

      <WhenViewingInvoice>
        <InvoiceViewer
          invoice={asUniversal}
          dictionary={localeStore.viewerDictionary as Dictionary}
          viewType={ViewType.EMBEDDED}
          paymentsQRCode={{
            payUrl: placeholder,
            isPlaceholder: true
          }}
        />
      </WhenViewingInvoice>
    </>
  );
};

export default observer(Wrapper);
