import flagsmith from 'flagsmith';

import { getIntlCurrency, SubscriptionTier } from '@invoice-simple/common';
import { FeatureName, InvoiceCreationLimit } from '@invoice-simple/feature-gate';

import UserModel, { Cadence } from 'src/models/UserModel';
import { defaultsStore } from 'src/stores/DefaultsStore';

export const getPaywallVariant = (
  currentTier: SubscriptionTier | null,
  tierRequired: SubscriptionTier | null
) => {
  if (currentTier === SubscriptionTier.ESSENTIALS && tierRequired === SubscriptionTier.PLUS)
    return 'essentials-to-plus';
  if (currentTier === SubscriptionTier.ESSENTIALS && tierRequired === SubscriptionTier.PREMIUM)
    return 'essentials-to-premium';
  if (currentTier === SubscriptionTier.PLUS && tierRequired === SubscriptionTier.PREMIUM)
    return 'plus-to-premium';
  return 'default';
};

/**
 * The reason the user hit the paywall.
 */
export const getReasonForUpgrade = (feature: FeatureName, user: UserModel) => {
  if (feature) return feature;
  if (user.isDocLimitedTier) {
    if (
      user.docQuota === InvoiceCreationLimit.TIER_1_LIMIT &&
      user.docsCountLastMonth >= user.docQuota
    ) {
      return '3-invoice-limit';
    }

    if (
      user.docQuota === InvoiceCreationLimit.TIER_2_LIMIT &&
      user.docsCountLastMonth >= user.docQuota
    ) {
      return '10-invoice-limit';
    }
  }

  return null;
};

export const getIntervalText = (cadence: Cadence) => {
  const user = UserModel.getInstance();
  const currencyCode = user.geoSubCurrencyCode?.toUpperCase();

  const renderIntervalText = (cadence: Cadence, currency: string) => {
    const defaultIntervalText = cadence === Cadence.ANNUAL ? '/yr' : '/mo';
    const currenciesWithPrefix = ['CAD', 'AUD'];

    const shouldShowPrefix = currenciesWithPrefix.includes(currency);
    const prefix = shouldShowPrefix ? currency : '';

    return `${prefix}${defaultIntervalText}`;
  };

  return renderIntervalText(cadence, currencyCode);
};

export const isFreeTierPaywallEnabled = () => {
  const user = UserModel.getInstance();

  const isFreeTierAbtestEnabled = flagsmith.hasFeature('free_tier_paywall');

  return !user.isGuest && !user.isSubPaid && !user.hadPaidSubsInThePast && isFreeTierAbtestEnabled;
};

export const getFormattedCurrency = (user: UserModel, amount: number) => {
  return getIntlCurrency({
    number: amount,
    currencyCode: user.geoSubCurrencyCode,
    localeCode: defaultsStore.locale.name
  });
};
