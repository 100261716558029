import React from 'react';

import UserModel from 'src/models/UserModel';
import { usePaymentsDashboardEligibility } from 'src/payments/hooks/usePaymentsDashboardEligibility';
import NavItem from './NavItem';

interface Props {
  isActive: boolean;
  isDisabled: boolean;
}

const PaymentsNavItem = ({ ...rest }: Props) => {
  const paymentsDashboardEligibility = usePaymentsDashboardEligibility();

  if (!paymentsDashboardEligibility.paymentsTabHref) {
    return null;
  }

  const sendEvent = () => {
    UserModel.getInstance().trackAppEventViaApi('paypal-navItem-clicked');
  };

  return (
    <NavItem
      data-testid="payments-nav-item"
      href={paymentsDashboardEligibility.paymentsTabHref}
      onClick={sendEvent}
      {...rest}>
      Payments
    </NavItem>
  );
};

export default PaymentsNavItem;
