import { Platform } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';

import { ISIntl } from 'src/i18n';
import alertModel from 'src/models/AlertModel';
import UserModel, { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { messages } from './messages';

export function getAlertMessageFromFeature(feature: FeatureName, ft: ISIntl['formatMessage']) {
  let title;
  let body = ft(messages.accessMoreFeatures);
  switch (feature) {
    case FeatureName.INVOICE_PHOTOS:
      title = ft(messages.featureGatePhotosTitle);
      break;
    case FeatureName.SIGNATURE:
      title = ft(messages.signatureFeatureUpgrade);
      break;
    case FeatureName.REQUEST_REVIEW:
      title = ft(messages.requestReviewFeatureUpgrade);
      break;
    case FeatureName.DUE_DATE_REMINDERS:
      title = ft(messages.dueDateReminderFeatureUpgrade);
      break;
    case FeatureName.REPORTS:
      title = ft(messages.reportsFeatureUpgrade);
      break;
    case FeatureName.EXPENSES:
      title = ft(messages.expensesFeatureUpgrade);
      break;
    case FeatureName.CLIENT_SIGNATURE:
      title = ft(messages.clientSignatureFeatureGateTitle);
      body = ft(messages.clientSignatureFeatureGateBody);
      break;
    case FeatureName.DEPOSITS:
      title = ft(messages.depositsFeatureGateTitle);
      body = ft(messages.depositsFeatureGateBody);
      break;
    case FeatureName.PREMIUM_TEMPLATES:
      title = ft(messages.premiumTemplatesFeatureGateTitle);
      body = ft(messages.premiumTemplatesFeatureGateBody);
      break;
  }
  return {
    title,
    body
  };
}

export const maybeShowAlertBanner = (store: AppStore, selectedInterval: Cadence, intl: ISIntl) => {
  const user = UserModel.getInstance();
  const { formatMessage, ft } = intl;

  const userTier = user.activeSubscriptionTier || undefined;
  const userCadence = user.activeSubscriptionCadence ?? selectedInterval;
  const feature = getURLQueryParam(URLQueryParamKeys.FEATURE) as FeatureName;
  const campaign = getURLQueryParam(URLQueryParamKeys.CAMPAIGN) as string;
  const ref = getURLQueryParam(URLQueryParamKeys.REF);

  const featureSource =
    feature ?? (!user.isWithinTieredMonthlyQuota ? `invoice-limit-${user.docQuota}` : null);
  user.events.trackAction('subscription-paywall', {
    ...store.getTieredPaywallEventData(userTier, userCadence),
    ...(user.isDocLimitedTier && {
      docsCountLastMonth: user.docsCountLastMonth
    }),
    tier: userTier,
    from: featureSource ?? ref,
    campaign: campaign || undefined,
    path: ref,
    platform: Platform.WEB,
    'from-guest-account': user.isGuest
  });

  const possibleRefs: string[] = [
    URLQueryParamKeys.CREATE_DOCUMENT,
    URLQueryParamKeys.SEND_DOCUMENT,
    URLQueryParamKeys.DOCUMENT_ACTIONS
  ];

  if (ref && possibleRefs.includes(ref)) {
    if (ref === URLQueryParamKeys.CREATE_DOCUMENT) {
      return alertModel.setAlert(
        'warning',
        formatMessage(messages.thankYouFreeTrial),
        formatMessage(messages.thankYouFreeTrialCreateMessage)
      );
    }

    if (ref === URLQueryParamKeys.SEND_DOCUMENT) {
      return alertModel.setAlert(
        'warning',
        formatMessage(messages.thankYouFreeTrial),
        formatMessage(messages.thankYouFreeTrialSendMessage)
      );
    }

    if (ref === URLQueryParamKeys.DOCUMENT_ACTIONS) {
      return alertModel.setAlert(
        'warning',
        formatMessage(messages.thankYouFreeTrial),
        formatMessage(messages.thankYouFreeTrialOptionsMessage)
      );
    }
  } else if (user.isDocLimitedTier) {
    alertDocQuotaReached(user, formatMessage);
  }

  /* Feature gating upgrade message */
  if (feature) {
    const { title, body } = getAlertMessageFromFeature(feature, ft);
    alertModel.setAlert('warning', title, body);
  }
};

const alertDocQuotaReached = (user: UserModel, formatMessage: ISIntl['formatMessage']) => {
  if (!user.isWithinTieredMonthlyQuota) {
    alertModel.setAlert(
      'warning',
      formatMessage(messages.titleInvoiceLimitReached, {
        starterQuota: user.docQuota
      }),
      formatMessage(messages.subtitleInvoiceLimitReached)
    );
  }
};
