import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { Platform, SubscriptionTier } from '@invoice-simple/common';
import { getMaxCouponDiscount, isTieredDiscount } from '@invoice-simple/is-coupon';

import { ISIntl } from 'src/i18n';
import { AppStore } from 'src/stores/AppStore';
import { titleizeAll } from 'src/util/Titleize';
import { PlanIntervalSelector } from './components';
import { BackButton } from './components/BackButton';
import { DiscountedPrice } from './components/DiscountedPrice';
import { SubscriptionButton } from './components/SubscriptionButton';
import { TieredMatrixListV2 } from './components/TieredMatrixListV2';
import { ValueProp } from './components/ValueProp';
import { useInterval } from './hooks/useInterval';
import { useUpgrade } from './hooks/useUpgrade';
import { getPaywallCouponInfo, shouldShowIntervalSelector } from './utils';
import { messages } from './utils/messages';

interface Props {
  store: AppStore;
  intl: ISIntl;
}

type TiersMap = {
  tier: SubscriptionTier;
  desktopInvoices: string;
  mobileInvoices: string;
};

export const DiscountedSubscriptionTiered = ({ store, intl }: Props) => {
  const { user } = store;
  const { ft, fta, formatMessage } = intl;

  const { selectedInterval, toggleInterval, shouldDisallowSwitch } = useInterval(
    user.activeSubscriptionCadence
  );

  const defaultTabState = 1;
  const tabState = useState(defaultTabState);
  const [selectedMobileTab, setSelectedMobileTab] = tabState;

  const tiers: TiersMap[] = [
    {
      tier: SubscriptionTier.ESSENTIALS,
      desktopInvoices: ft(messages.discountedPaywallInvoiceMonthlyLimit, {
        limit: user.tierOneMonthlyQuota
      }),
      mobileInvoices: ft(messages.invoiceMonthlyLimitEssentials, {
        limit: user.tierOneMonthlyQuota
      })
    },
    {
      tier: SubscriptionTier.PLUS,
      desktopInvoices: ft(messages.discountedPaywallInvoiceMonthlyLimit, {
        limit: user.plusMonthlyQuota
      }),
      mobileInvoices: ft(messages.invoiceMonthlyLimitEssentials, {
        limit: user.plusMonthlyQuota
      })
    },
    {
      tier: SubscriptionTier.PREMIUM,
      desktopInvoices: ft(messages.discountedPaywallUnlimited),
      mobileInvoices: ft(messages.discountedPaywallUnlimited)
    }
  ];

  const { loadingCheckout, getCouponPrices, onBuyClick } = useUpgrade({
    store,
    intl,
    selectedInterval
  });

  const spacingClasses = (index: number) => {
    return {
      'pr-[20px]': index === 0,
      'pl-[20px]': index === tiers.length - 1,
      'px-[10px]': index > 0 && index < tiers.length - 1
    };
  };

  const couponInfo = getPaywallCouponInfo();
  if (!couponInfo) {
    return null;
  }

  useEffect(() => {
    const userTier = user.activeSubscriptionTier ?? undefined;
    const userCadence = user.activeSubscriptionCadence ?? selectedInterval;

    user.events.trackAction(
      'subscription-paywall',
      store.getDiscountedPaywallEventData(userTier, userCadence, couponInfo.name)
    );
  }, []);

  const getCouponDisplayPrices = (tier: SubscriptionTier) => {
    const { originalPriceDisplay, discountPriceDisplay } = getCouponPrices(
      couponInfo,
      tier,
      selectedInterval
    );
    return { originalPriceDisplay, discountPriceDisplay, tier, cadence: selectedInterval };
  };

  const isTiered = isTieredDiscount(couponInfo.web);

  const translations = {
    upgradeNowTitle: ft(
      isTiered ? messages.discountedPayWallTitleTiered : messages.discountedPayWallTitle,
      {
        discount: getMaxCouponDiscount({ paywallCouponConfig: couponInfo, platform: Platform.WEB }),
        duration: couponInfo?.durationMonths
      }
    ),
    discountValueTitle: ft(
      isTiered
        ? messages.discountedPaywallTableTopTitleTiered
        : messages.discountedPaywallTableTopTitle,
      {
        discount: getMaxCouponDiscount({ paywallCouponConfig: couponInfo, platform: Platform.WEB }),
        duration: couponInfo?.durationMonths
      }
    ),
    upgradeNowTestId: isTiered ? 'upgrade-now-title-tiered' : 'upgrade-now-title-non-tiered',
    discountValueTitleTestId: isTiered
      ? 'discount-value-title-tiered'
      : 'discount-value-title-non-tiered'
  };

  const onSupportClick = async () => {
    window.open('https://help.invoicesimple.com');
  };

  const showIntervalSelector = shouldShowIntervalSelector(couponInfo);

  return (
    <div id="tailwind">
      <div
        className="relative mx-auto sm:py-24 px-4 sm:px-6 md:px-8"
        style={{ maxWidth: '1000px' }}>
        <BackButton />
        <div className="sm:align-center sm:flex sm:flex-col">
          <div className="md:max-w-[600px] text-5xl font-bold text-gray-900 sm:text-center my-0 mx-auto">
            <img
              data-testid="is-logo"
              className="h-24 sm:h-28 w-auto rounded"
              src="images/is-logo.png"
              alt="Invoice Simple Logo"
            />
            <h2
              data-testid={translations.upgradeNowTestId}
              className="font-bold text-gray-900 md:text-center"
              style={{ fontSize: 26, lineHeight: '36px', paddingTop: 30, paddingBottom: 30 }}>
              {translations.upgradeNowTitle}
            </h2>

            <h3
              data-testid="upgrade-now-subtitle"
              className="font-semibold text-gray-800 mx-auto md:text-center "
              style={{ fontSize: 18, lineHeight: '28px', paddingBottom: 30 }}>
              {ft(messages.discountedPayWallSubTitle)}
            </h3>

            <div className="valueProps tracking-normal gap-4 grid grid-cols-1 md:grid-cols-2 mt-0 md:mt-[30px]">
              <ValueProp
                width={30}
                height={30}
                imageUrl="/images/icons/invoices.svg"
                message={ft(messages.discountedPaywallValueProp1)}
              />

              <ValueProp
                containerWidth={30}
                width={24}
                height={30}
                imageUrl="/images/icons/notifications.png"
                message={ft(messages.discountedPaywallValueProp2)}
              />

              <ValueProp
                width={30}
                height={30}
                imageUrl="/images/icons/pc_mobile.svg"
                message={ft(messages.discountedPaywallValueProp3)}
              />

              <ValueProp
                width={30}
                height={30}
                imageUrl="/images/icons/pen-write.svg"
                message={ft(messages.discountedPaywallValueProp4)}
              />
            </div>
          </div>

          <h3
            className="text-gray-800 text-center font-semibold mt-[40px] mx-auto md:mt-[60px] mb-[10px] md:mb-0 md:max-w-[500px] tracking-normal"
            style={{ fontSize: 20, lineHeight: '28px' }}>
            {ft(messages.discountedPaywallJoinInvoiceSimple)}
          </h3>

          <div className="flex justify-center pt-[30px] space-x-[8px]">
            <img src="/images/icons/star.svg" />
            <img src="/images/icons/star.svg" />
            <img src="/images/icons/star.svg" />
            <img src="/images/icons/star.svg" />
            <img src="/images/icons/star_partial.svg" />
          </div>

          <div className="mt-[40px] md:mt-[60px] mx-auto">
            <h3
              className="text-center font-semibold mx-auto mb-[10px] sm:max-w-[500px] tracking-normal text-gray-900"
              data-testid={translations.discountValueTitleTestId}
              style={{ fontSize: 20, lineHeight: '28px' }}>
              {translations.discountValueTitle}
            </h3>

            <p className="text-center font-normal" style={{ fontSize: 14, lineHeight: '20px' }}>
              {ft(messages.discountedPaywallTableTopSubtitle, {
                duration: couponInfo?.durationMonths
              })}
            </p>
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="bg-white pt-[10px] sm:align-center sm:flex sm:flex-col sticky top-0">
            {showIntervalSelector && (
              <PlanIntervalSelector
                selectedInterval={selectedInterval}
                onToggleInterval={toggleInterval}
                formatMessage={formatMessage}
                isHidden={user.hasAnnualSub}
              />
            )}
            <div className="hidden md:block mt-[20px] mx-[25px]">
              <table className="h-px w-full table-fixed">
                <caption className="sr-only">Pricing plan comparison</caption>
                <thead className="border-bottom border-gray-200">
                  <tr>
                    <th className="pb-4 text-left text-2xl font-medium text-gray-900" scope="col">
                      <span className="sr-only">Feature by</span>
                      <span>{ft(messages.tieredPlans)}</span>
                    </th>
                    {tiers.map((tier, index) => {
                      return (
                        <th
                          key={tier.tier}
                          className="w-1/4 pb-4 text-left text-2xl font-medium text-gray-900"
                          scope="col">
                          <span className={classNames(spacingClasses(index))}>
                            {titleizeAll(tier.tier)}
                          </span>
                          {tier.tier === SubscriptionTier.PLUS && (
                            <span
                              style={{
                                backgroundColor: 'rgba(69, 94, 222, 0.1)',
                                color: 'rgba(69, 94, 222, 1)',
                                fontWeight: 700,
                                lineHeight: '22px',
                                fontSize: 12,
                                cursor: 'default',
                                fontFamily:
                                  'Roboto, system-ui, -apple-system, Segoe UI, Ubuntu, Cantarell, Noto Sans, sans-serif'
                              }}
                              className="inline-block rounded-full py-1 px-4 ml-3 uppercase">
                              {fta(messages.mostPopular)}
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 border-t border-gray-200">
                  <tr className="border-bottom border-gray-200">
                    <th
                      className="py-8 text-left align-top text-xl font-medium text-gray-90"
                      scope="row">
                      {ft(messages.invoicesPerMonth)}
                    </th>
                    {tiers.map((tier, index) => (
                      <td key={tier.tier}>
                        <p className={classNames(spacingClasses(index))}>{tier.desktopInvoices}</p>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      className="py-8 text-left align-top text-xl font-medium text-gray-900"
                      scope="row">
                      {ft(messages.tieredPricing)}
                    </th>
                    {tiers.map((tier, index) => (
                      <td
                        key={tier.tier}
                        data-testid={`${tier.tier}-tier-details`}
                        className="h-full py-8 align-top">
                        <div className={classNames('mb-10', spacingClasses(index))}>
                          <DiscountedPrice {...getCouponDisplayPrices(tier.tier)} />
                        </div>
                        <div className={classNames(spacingClasses(index))}>
                          <SubscriptionButton
                            text={ft(messages.discountedPaywallBuyNow)}
                            tier={tier.tier}
                            cadence={selectedInterval}
                            loading={loadingCheckout}
                            onClick={onBuyClick}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="md:hidden mt-[20px] tierTabs grid grid-cols-3">
              {tiers.map((tier, index) => (
                <div key={tier.tier}>
                  <button
                    style={{
                      fontSize: 16,
                      ...(index === selectedMobileTab
                        ? {
                            color: '#455EDE',
                            borderBottomWidth: 0,
                            borderTopWidth: 1,
                            borderLeftWidth: 1,
                            borderRightWidth: 1
                          }
                        : {
                            borderBottomWidth: 1,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                            borderRightWidth: 0
                          })
                    }}
                    onClick={() => setSelectedMobileTab(index)}
                    className="w-full h-20 bg-white py-[15px] rounded-t-lg border-gray-200 border-solid">
                    {titleizeAll(tier.tier)}
                  </button>
                </div>
              ))}

              {tiers.map((tier, index) => (
                <div
                  key={tier.tier}
                  className="flex flex-col w-full items-center"
                  style={{
                    paddingBottom: 20,
                    border: 0,
                    borderBottomWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#E5E7EB'
                  }}>
                  <div
                    className={`flex py-6 text-xl font-normal text-gray-900 ${
                      index === 0 ? 'visible items-start' : 'invisible'
                    }`}>
                    <p className="text-xl font-medium text-gray-900">
                      {ft(messages.invoicesPerMonth)}
                    </p>
                  </div>
                  <p className="text-xl font-normal text-gray-900">{tier.mobileInvoices}</p>
                </div>
              ))}
            </div>
          </div>

          <TieredMatrixListV2
            store={store}
            intl={intl}
            selectedInterval={selectedInterval}
            shouldDisallowSwitch={shouldDisallowSwitch}
            showTabState={tabState}
            tierRequired={null}
            hideFeatures={{ Core: true }}
          />

          <div className="flex flex-col sm:flex-row justify-start sm:justify-center sm:items-center pt-15">
            <p className="text-gray-800 font-normal" style={{ fontSize: 16, lineHeight: '22px' }}>
              {ft(messages.discountedPaywallSupportHelp)}...
            </p>

            <button
              type="button"
              onClick={() => onSupportClick()}
              className="mt-3 sm:mt-0 sm:ml-6 !rounded-[6px] border-0 bg-gray-100 px-[21px] py-[13px] w-fit font-medium"
              style={{ fontSize: 14, lineHeight: '22px' }}>
              {ft(messages.discountedPaywallContactSupport)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
