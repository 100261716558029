import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { Cadence } from 'src/models/UserModel';
import { tierCardData } from '../../../utils';
import { CardPurchaseFooter } from './CardPurchaseFooter';
import { TierCard } from './TierCard';

interface MobileTierCardsProps {
  selectedTier: SubscriptionTier;
  setSelectedTier: (tier: SubscriptionTier) => void;
  selectedInterval: Cadence;
  loading: boolean;
  handleSubscriptionPurchase: (tier: SubscriptionTier) => void;
}

export const MobileTierCards = ({
  loading,
  selectedTier,
  setSelectedTier,
  selectedInterval,
  handleSubscriptionPurchase
}: MobileTierCardsProps) => {
  const selectMobilePlan = (tier: SubscriptionTier) => {
    setSelectedTier(tier);
  };

  const tierData = tierCardData();

  return (
    <div
      className="md:hidden flex flex-col gap-10 justify-center items-center"
      data-testid="mobile-tier-cards">
      {tierData.reverse().map((tier) => (
        <TierCard
          key={tier.name}
          tier={tier}
          features={tier.features.splice(0, 3)}
          selectedTier={selectedTier}
          selectMobilePlan={selectMobilePlan}
          selectedInterval={selectedInterval}
        />
      ))}
      <CardPurchaseFooter
        handleSubscriptionPurchase={handleSubscriptionPurchase}
        loading={loading}
        selectedInterval={selectedInterval}
        selectedTier={selectedTier}
      />
    </div>
  );
};
