import React from 'react';

import { ISIntl, useISIntl } from 'src/i18n';
import AlertModel from 'src/models/AlertModel';
import LocationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import { stringToDate } from 'src/util/date';
import { getInvoiceModel } from 'src/util/getInvoiceModel';
import { navInvoiceLimit } from 'src/util/navigation';

import { getInvoiceBalance, getInvoiceTotalWithPaidSurcharge } from '@invoice-simple/calculator';
import { DocTypes, Platform } from '@invoice-simple/common';
import { Document, EstimateSettings } from '@invoice-simple/domain-invoicing';

import { CurrencyDisplay } from '../Currency';
import { useDialog } from '../DialogModal/useDialog';
import { DropdownMenu, KebabMenu, MenuItem } from '../DropdownMenu/DropdownMenu';
import { LocalizedDate } from '../Localized/LocalizedDate';

import './InvoiceRow.scss';

import { useQueryClient } from '@tanstack/react-query';

import { paymentMethodOptions } from 'src/data/paymentMethodOptions';
import { isMigratedEditorEnabled } from 'src/util/isMigratedEditorEnabled';

interface InvoiceRowProps {
  invoice: Document;
  isPaid: boolean;
  onClick: (id?: string) => void;
  intl?: ISIntl;
}

export const InvoiceRow: React.FC<InvoiceRowProps> = (props) => {
  const handleClick = () => {
    props.onClick(props.invoice.objectId);
  };

  const { invoice } = props;
  const intl = useISIntl();
  const { f, fta } = intl!;
  const user = UserModel.getInstance();
  const { client, ...restOfInvoice } = invoice;
  const { openDialogModal } = useDialog();
  const invoiceModel = getInvoiceModel(invoice);

  const queryClient = useQueryClient();

  const universalInvoice = {
    client: {
      shipAmount: client?.shipAmount
    },
    ...restOfInvoice
  };

  const displayTotal = props.isPaid
    ? getInvoiceTotalWithPaidSurcharge(universalInvoice)
    : getInvoiceBalance(universalInvoice);

  const hasBalance = () => {
    return +getInvoiceBalance(universalInvoice) > 0;
  };

  const hasPayments = () => {
    return (invoiceModel.payments?.length || 0) > 0;
  };

  const isPaid = () => {
    const hasNoBalance = +getInvoiceBalance(universalInvoice) <= 0;
    return hasNoBalance && hasPayments();
  };

  function handleViewHistory() {
    invoiceModel.showHistory();
  }

  const handleGetLink = async () => {
    return invoiceModel.openPublicLink();
  };

  const handleEmail = async () => {
    return invoiceModel.showEmailForm();
  };

  const handlePrint = async () => {
    return invoiceModel.print();
  };

  const handleMarkPaid = async (paymentMethod: string) => {
    await invoiceModel.markPaid(getPaymetMethodLabel(paymentMethod));
    queryClient.invalidateQueries(['documents']);
  };

  const handleMarkUnpaid = async () => {
    await invoiceModel.markUnpaid();
    queryClient.invalidateQueries(['documents']);
  };

  const getPaymetMethodLabel = (paymentMethod: string) => {
    return paymentMethodOptions.find((p) => p.value === paymentMethod)?.value || 'other';
  };

  const handleConvertEstimate = async () => {
    if (!user.canCreateNewDoc(DocTypes.DOCTYPE_INVOICE)) {
      return navInvoiceLimit(user);
    }

    try {
      const oldEstimate = getInvoiceModel(invoice);
      const newInvoice = getInvoiceModel(await oldEstimate.convertEstimate());

      user.events.trackAction('estimate-to-invoice', {
        estimateRemoteId: oldEstimate.remoteId,
        invoiceRemoteId: newInvoice.remoteId,
        platform: Platform.WEB,
        'client-signature': oldEstimate.setting?.estimateSignatureRequired
          ? oldEstimate.setting.estimateSignedAt
            ? 'signed'
            : 'unsigned'
          : 'off'
      });

      user.events.trackAction('invoice-create', {
        invoiceId: newInvoice.id,
        'doc-type': DocTypes.DOCTYPE_INVOICE,
        color: newInvoice.setting.color ?? '',
        platform: Platform.WEB,
        template: newInvoice.setting.template
      });

      if (isMigratedEditorEnabled(user.countryCode)) {
        window.location.assign(`${process.env.REACT_APP_URL}/invoices/${newInvoice.id}?email=true`);
      } else {
        LocationModel.navAndScrollTop('invoiceView', { id: newInvoice.id });
      }

      AlertModel.setAlert(
        'success',
        f(
          { id: 'invoice.make.alert.success.title' },
          { invoiceNo: newInvoice.invoiceNo, estimateNo: oldEstimate.invoiceNo }
        )
      );
    } catch (error) {
      if (/subscription/.test(error.message)) {
        LocationModel.nav('subscription');
      } else {
        AlertModel.setAlert('danger', f({ id: 'invoice.make.alert.failed.title' }), error.message);
      }
    }
  };

  const handleDelete = () => {
    const alertConfig = {
      title: fta({ id: 'invoice.invoiceControls.delete' }),
      message: f({ id: 'invoice.mobileEdit.delete.confirm' }, { invoiceNo: invoice.invoiceNo }),
      type: 'alert' as const,
      onContinue: async () => {
        await invoiceModel.markDeleted();
        window.location.reload();
      }
    };
    openDialogModal(alertConfig);
  };

  return (
    <tr className="invoice-row" key={invoice.objectId} onClick={handleClick}>
      <td className="invoice-col-id">
        <span className="invoice-id">{invoice.invoiceNo}</span>
      </td>

      <td className="invoice-col-client">
        <p>{invoice.client && invoice.client.name}</p>
      </td>

      <td className="invoice-col-date d-none d-sm-table-cell">
        {<LocalizedDate date={stringToDate(invoice.invoiceDate)} />}
      </td>
      <td className="invoice-col-state" />
      <td className="invoice-col-balance">
        <CurrencyDisplay value={+displayTotal} />
        {isPaid() && (
          <>
            <br />
            <span className="invoice-balance-paid">
              {f({ id: 'invoice.itemRow.subtext.paid' })}
            </span>
          </>
        )}
        {invoiceModel.isSignedEstimate && (
          <>
            <br />
            <span className="invoice-estimate-signed">
              {f({ id: 'doc.history.signed' })}{' '}
              <LocalizedDate
                date={new Date((invoice.setting as EstimateSettings).estimateSignedAt!)}
              />
            </span>
          </>
        )}
      </td>
      <td>
        <KebabMenu>
          {invoiceModel.isInvoice && hasBalance() && (
            <DropdownMenu label={f({ id: 'kebab.mark.paid' })} inKebabMenu={true}>
              <MenuItem
                label={f({ id: 'payment.method.label.banktransfer' })}
                onClick={() => handleMarkPaid('bank')}
              />
              <MenuItem
                label={f({ id: 'payment.method.label.cash' })}
                onClick={() => handleMarkPaid('cash')}
              />
              <MenuItem
                label={f({ id: 'payment.method.label.check' })}
                onClick={() => handleMarkPaid('check')}
              />
              <MenuItem
                label={f({ id: 'payment.method.label.creditcard' })}
                onClick={() => handleMarkPaid('creditCard')}
              />
              <MenuItem
                label={f({ id: 'payment.method.label.debit' })}
                onClick={() => handleMarkPaid('debit')}
              />
              <MenuItem
                label={f({ id: 'payment.method.label.mobilePaymentApp' })}
                onClick={() => handleMarkPaid('mobilePaymentApp')}
              />
              <MenuItem
                label={f({ id: 'payment.method.label.other' })}
                onClick={() => handleMarkPaid('other')}
              />
            </DropdownMenu>
          )}
          {invoiceModel.isInvoice && hasPayments() && !hasBalance() && (
            <MenuItem label={f({ id: 'kebab.mark.unpaid' })} onClick={handleMarkUnpaid} />
          )}
          {invoiceModel.isEstimate && (
            <MenuItem label={f({ id: 'kebab.make.invoice' })} onClick={handleConvertEstimate} />
          )}
          <MenuItem label={f({ id: 'kebab.history' })} onClick={handleViewHistory} />
          <MenuItem label={f({ id: 'kebab.get.link' })} onClick={handleGetLink} />
          <MenuItem label={f({ id: 'kebab.email' })} onClick={handleEmail} />
          <MenuItem label={f({ id: 'kebab.print' })} onClick={handlePrint} />
          <MenuItem
            className="separator-top delete-button"
            label={f({ id: 'kebab.delete' })}
            onClick={handleDelete}
          />
        </KebabMenu>
      </td>
    </tr>
  );
};
