export enum PaymentEventFeatures {
  'paypalRedesign' = 'paypal-redesign'
}

export enum PaymentEventPaymentsHolder {
  'invoiceEditor' = 'invoice-editor',
  'settings' = 'settings'
}

export enum PaymentEventSource {
  'invoiceEditor' = 'invoice-editor',
  'settings' = 'settings',
  'invoiceList' = 'invoice-list',
  'estimateEditor' = 'estimate-editor',
  'paymentsDashboard' = 'payments-dashboard',
  'notDefined' = 'not defined'
}

export enum PaymentEventAction {
  'open' = 'open',
  'click' = 'click',
  'close' = 'close',
  'toggleOn' = 'toggle-on',
  'toggleOff' = 'toggle-off'
}

export enum PaymentEventType {
  'box' = 'box',
  'modal' = 'modal'
}

export enum PaymentEventActivePaymentProvider {
  'paypal' = 'PayPal',
  'stripe' = 'Stripe'
}

export enum PaymentEventComponent {
  'managePaymentSettings' = 'manage-payment-settings',
  'learnMore' = 'learn-more',
  'modalLearnMore' = 'modal-learn-more',
  'modalLearnMoreClose' = 'modal-learn-more-close',

  'tileInitial' = 'tile-initial',
  'modalInitial' = 'modal-initial',
  'tos' = 'tos',
  'modalCloseInitial' = 'modal-close-initial',

  'tilePending' = 'tile-pending',
  'modalPending' = 'modal-pending',
  'modalClosePending' = 'modal-close-pending',

  'tileOnboarded' = 'tile-onboarded',
  'modalOnboarded' = 'modal-onboarded',
  'modalCloseOnboarded' = 'modal-close-onboarded',

  'tileOnboardedEmailConfirmed' = 'tile-onboarded-email-confirmed',
  'modalOnboardedEmailConfirmed' = 'modal-onboarded-email-confirmed',
  'modalCloseOnboardedEmailConfirmed' = 'modal-close-onboarded-email-confirmed',

  'tilePendingVerification' = 'tile-pending-verification',
  'modalPendingVerification' = 'modal-pending-verification',
  'modalClosePendingVerification' = 'modal-close-pending-verification',

  'tileClosed' = 'tile-closed',
  'modalClosed' = 'modal-closed',
  'modalCloseClosed' = 'modal-close-closed',

  'tileGlobalEnabled' = 'tile-global-enabled',
  'tileGlobalDisabled' = 'tile-global-disabled',
  'tileEnabledInvoiceAccepting' = 'tile-enabled-invoice-accepting',
  'tileDisabledInvoiceAccepting' = 'tile-disabled-invoice-accepting',
  'modalEnabling' = 'modal-enabling',
  'modalEnablingClose' = 'modal-enabling-close',
  'manageAccount' = 'manage-account',
  'manageAccountPendingVerification' = 'manage-account-pending-verification',

  'tileCurrencyMismatchFix' = 'tile-currency-mismatch-fix',
  'modalCurrencyMismatchFix' = 'modal-currency-mismatch-fix',
  'modalCurrencyMismatchFixClose' = 'modal-currency-mismatch-fix-close',

  'stripePaymentsTab' = 'stripe-payments-tab',
  'stripePayoutsTab' = 'stripe-payouts-tab',
  'paypalPaymentsTab' = 'paypal-payments-tab'
}
