import axios from 'axios';

import { SubscriptionTier } from '@invoice-simple/common';
import { ISPaywallCoupon } from '@invoice-simple/is-coupon';

import type { AppStore } from '../stores/AppStore';
import { Cadence } from 'src/models/UserModel';
import localeStore from 'src/stores/LocaleStore';
import environmentStore from '../stores/EnvironmentStore';
import { removeUndefinedOrNull } from './functions';

type GetFeatureListImageUrlParams = {
  tier: SubscriptionTier | undefined;
  language: string;
  coupon: string | undefined;
};

function getStripeMetadata({
  store,
  orderSku,
  couponSku,
  couponName,
  tier,
  cadence,
  toBeTransferred,
  source
}: {
  store: AppStore;
  orderSku: string;
  couponSku?: string;
  couponName?: ISPaywallCoupon;
  tier?: SubscriptionTier;
  cadence?: Cadence;
  toBeTransferred?: boolean;
  source?: string;
}) {
  const { user } = store;
  return {
    userId: user.id,
    accountId: user.accountId,
    orderSku,
    couponSku,
    couponName,
    source,
    referralCode: user.advocateReferralCode,
    currencyCode: user.geoSubCurrencyCode,
    app: environmentStore.appHost,
    ...store.getSubscriptionEventData({ tier, cadence, couponSku }),
    ...removeUndefinedOrNull({
      isApp: environmentStore.appHost,
      isPlatform: 'web',
      isCountry: user.countryCode,
      isVersion: environmentStore.getReactAppVersion(),
      isAccount: user.accountId,
      isUser: user.id,
      isInstallation: environmentStore.installationId,
      parseSessionToken: user.sessionToken,
      toBeTransferred
    })
  };
}

interface CheckoutSessionRequestBody {
  productTitle: string;
  description?: string;
  price: number;
  currency: string;
  locale: string;
  featureListImageUrl: string;
  userEmail: string | undefined;
  isGuestUser: boolean;
  stripeMetadata: any;
  successUrl?: string;
  cancelUrl?: string;
}

interface CheckoutConfig {
  store: AppStore;
  productTitle: string;
  description?: string;
  price: number;
  orderSku: string;
  successUrl?: string;
  cancelUrl?: string;
  couponSku?: string;
  couponName?: ISPaywallCoupon;
  tier?: SubscriptionTier;
  cadence?: Cadence;
  toBeTransferred?: boolean;
}

function getFeatureListImageUrl(params: GetFeatureListImageUrlParams) {
  const { tier, language, coupon } = params;

  const availableLanguages = ['en', 'es', 'fr', 'de', 'it', 'nl'];
  const languageCode = availableLanguages.includes(language) ? language : 'en';
  const imageName = tier ? `${tier}-${languageCode}` : languageCode;
  const image = coupon ? `${imageName}-${coupon}` : imageName;
  return `${environmentStore.cdnURL}/images/feature-list/${image}.png`;
}

function getCheckoutSessionRequestBody(checkoutConfig: CheckoutConfig): CheckoutSessionRequestBody {
  const {
    store,
    productTitle,
    description,
    price,
    orderSku,
    successUrl,
    cancelUrl,
    couponSku,
    couponName,
    tier,
    cadence,
    toBeTransferred
  } = checkoutConfig;
  const { user } = store;
  const locale = localeStore.currentLocaleCountryCode;

  const coupon = couponName === '50off6months' ? '50off6months' : undefined;

  const featureListImageUrl = getFeatureListImageUrl({
    tier,
    language: locale.toLowerCase() || 'en',
    coupon
  });

  return {
    productTitle,
    description,
    price,
    currency: user.geoSubCurrencyCode,
    locale,
    featureListImageUrl,
    userEmail: user.email,
    isGuestUser: user.isGuest,
    successUrl,
    cancelUrl,
    stripeMetadata: getStripeMetadata({
      store,
      orderSku,
      couponSku,
      couponName,
      tier,
      cadence,
      toBeTransferred,
      source: user.accountSource
    })
  };
}

export async function redirectToStripeCheckout(checkoutConfig: CheckoutConfig) {
  const { store, tier, cadence, couponSku } = checkoutConfig;

  const response = await axios.post(
    '/stripe/sessions',
    getCheckoutSessionRequestBody(checkoutConfig)
  );
  const url = response?.data?.url;
  if (url) {
    await store.user.events.trackAction(
      'subscription-checkout',
      store.getSubscriptionEventData({ tier, cadence, couponSku })
    );
    window.location.assign(url);
  } else {
    throw new Error('Checkout URL is undefined');
  }
}

interface StripeCustomer {
  name: string;
  email: string;
}

export async function getStripeCustomerFromSession(sessionId: string): Promise<StripeCustomer> {
  const response = await axios.get<StripeCustomer>(`/stripe/sessions/${sessionId}/customer`);
  return response.data;
}

interface StripeCheckoutSession {
  customer_details: {
    name: string;
    email: string;
  };
  metadata: {
    accountId: string;
    toBeTransferred?: boolean;
    couponSku?: string;
    couponName?: ISPaywallCoupon;
  };
}

export async function getStripeCheckoutSession(sessionId: string): Promise<StripeCheckoutSession> {
  const { data } = await axios.get<StripeCheckoutSession>(`/stripe/sessions/${sessionId}`);
  return data;
}
