import React from 'react';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { AppStore } from '../stores/AppStore';

import './Subscription.css';
import './SubscriptionPaywallAbtest.css';

import { FormattedMessage } from 'react-intl';

import { defineMessages, injectIntl } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import { PaywallType } from 'src/types/Paywall';
import { Loading } from './Loading';
import SubscriptionFree from './SubscriptionFree';
import {
  DiscountedSubscriptionTiered,
  PremiumLegacy,
  SubscriptionTieredV1,
  SubscriptionTieredV2,
  SubscriptionTieredV3
} from './SubscriptionPaywall';
import { maybeAlertCoupon } from './SubscriptionPaywall/utils';
import SubscriptionTrial from './SubscriptionTrial';

interface Prop {
  store: AppStore;
  onLoad: () => void;
  intl: ISIntl;
}

const messages = defineMessages({
  title: {
    id: 'subscription.title',
    defaultMessage: 'Subscription Upgrade'
  },
  description: {
    id: 'subscription.description',
    defaultMessage: `Upgrade to pro for unlimited invoices.`
  },
  header: {
    id: 'subscription.header',
    defaultMessage: 'You have a PRO subscription.'
  },
  subHeader: {
    id: 'subscription.subHeader',
    defaultMessage: 'Unlimited Invoices and Estimates, no need to worry.'
  },
  linkBack: {
    id: 'subscription.links.back',
    defaultMessage: 'Back to Invoice List'
  }
});

@injectIntl
@observer
export default class Subscription extends React.Component<Prop, any> {
  async componentDidMount() {
    this.props.onLoad();
    const user = this.props.store.user;
    await user.loadPromise;

    // Alert user if they are using an invalid coupon
    maybeAlertCoupon(user);
  }
  handleCancel = (e) => {
    e.preventDefault();
    this.props.store.nav('invoiceList');
  };

  render() {
    return this.props.store.user.isLoaded ? this.renderPaywall() : <Loading />;
  }
  renderPaywall() {
    const store = this.props.store;
    const user = store.user;

    const intl = this.props.intl;
    const f = intl.formatMessage;
    const paywallType = user.paywallType;

    return (
      <div className={`subscription`}>
        <Helmet>
          <title itemProp="name">{f(messages.title)}</title>
          <body
            className={`app-theme ${
              /* app-theme-focus has dark background, tiered paywall should have light background */
              paywallType === PaywallType.TIERED_V1 ||
              paywallType === PaywallType.TIERED_V2 ||
              paywallType === PaywallType.DISCOUNTED ||
              paywallType === PaywallType.PREMIUM_LEGACY ||
              paywallType === PaywallType.TIERED_V3
                ? 'bg-white'
                : 'app-theme-focus'
            }`}
          />
          <meta name="description" content={f(messages.description)} />
        </Helmet>

        {paywallType === PaywallType.PRO && (
          <div className="focus-container">
            <div className="focus-header">
              <a className="brand" href="/" onClick={(e) => this.handleCancel(e)}>
                <img
                  className="brand-logo"
                  src="/images/logo.png"
                  role="presentation"
                  alt="invoice simple logo"
                  data-pin-nopin="true"
                />
              </a>
              <h2>
                <FormattedMessage {...messages.header} />
              </h2>
              <h3>
                <FormattedMessage {...messages.subHeader} />.
              </h3>
            </div>
            <div className="focus-footer">
              <a
                className="btn btn-subscription-status-back"
                href="/invoices"
                onClick={(e) => this.handleCancel(e)}>
                <FormattedMessage {...messages.linkBack} />
              </a>
            </div>
          </div>
        )}

        {paywallType === PaywallType.TRIAL && (
          <SubscriptionTrial store={store} onCancel={this.handleCancel} intl={intl} />
        )}

        {paywallType === PaywallType.FREE && (
          <SubscriptionFree store={store} onCancel={this.handleCancel} intl={intl} />
        )}

        {paywallType === PaywallType.TIERED_V1 && (
          <SubscriptionTieredV1 store={store} intl={intl} />
        )}

        {paywallType === PaywallType.TIERED_V2 && (
          <SubscriptionTieredV2 store={store} intl={intl} />
        )}

        {paywallType === PaywallType.DISCOUNTED && (
          <DiscountedSubscriptionTiered store={store} intl={intl} />
        )}

        {paywallType === PaywallType.PREMIUM_LEGACY && <PremiumLegacy store={store} intl={intl} />}

        {paywallType === PaywallType.TIERED_V3 && (
          <SubscriptionTieredV3 store={store} intl={intl} />
        )}
      </div>
    );
  }
}
