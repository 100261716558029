import React from 'react';
import cn from 'classnames';

import injectStore from 'src/util/injectStore';
import { defineMessages } from 'src/i18n';

import { CurrencyDisplay } from '../Currency';
import { Desktop, Mobile } from '../Responsive';
import { getTaxLabel } from 'src/models/TaxLabelModel';
import './TotalAmounts.scss';
import {
  getSubtotal,
  getDiscount,
  getInvoiceTax,
  getInvoiceTotal,
  getPaidAmount,
  getInvoiceBalance
} from '@invoice-simple/calculator';
import { DiscountTypes, UniversalInvoice, DocTypes, TaxTypes } from '@invoice-simple/common';
import { useISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';

const _SummaryRow = ({ label, content, bold = false, desktop = false, className, ...rest }) => (
  <div className={cn('invoice-totals-row', className, { bold, desktop })} {...rest}>
    <div className="invoice-summary-label">{label}</div>
    <div className="invoice-summary-value">{content}</div>
  </div>
);

const SummaryRow = (props) => (
  <>
    <Desktop>
      <_SummaryRow desktop {...props} />
    </Desktop>
    <Mobile>
      <_SummaryRow {...props} />
    </Mobile>
  </>
);

const messages = defineMessages({
  subtotal: {
    id: 'invoice.amounts.subtotal',
    defaultMessage: 'subtotal'
  },
  total: {
    id: 'invoice.amounts.total',
    defaultMessage: 'total'
  },
  payments: {
    id: 'invoice.amounts.payments',
    defaultMessage: 'payments'
  },
  balanceDue: {
    id: 'invoice.amounts.balanceDue',
    defaultMessage: 'balance due'
  },
  discount: {
    id: 'invoice.amounts.discount',
    defaultMessage: 'discount'
  },
  inclusive: {
    id: 'invoice.amounts.taxInclusivePrefix',
    defaultMessage: 'inc'
  },
  addItem: {
    id: 'invoice.buttons.item',
    defaultMessage: 'item'
  }
});

const SummaryTax = ({ invoice, taxLabel }: { invoice: UniversalInvoice; taxLabel: string }) => {
  const intl = useISIntl();
  if (invoice.setting.taxType == TaxTypes.TAX_NONE) {
    return null;
  }

  let prefix = '';
  if (invoice.setting.taxInclusive && invoice.setting.taxType !== TaxTypes.TAX_DEDUCTED) {
    prefix = intl.formatMessage(messages.inclusive);
  }

  return (
    <SummaryRow
      label={taxLabel}
      className="invoice-summary-tax"
      data-selector="invoice-summary-tax"
      content={<CurrencyDisplay value={+getInvoiceTax(invoice)} prefix={prefix} />}
    />
  );
};

const TotalAmounts = ({ store }: { store: AppStore }) => {
  const invoice = store.doc;
  const universalInvoice: UniversalInvoice = invoice.asUniversal;
  const { fta } = useISIntl();
  const totalLabel = invoice.isEstimate ? fta(messages.total) : fta(messages.balanceDue);
  let taxLabel = getTaxLabel(invoice, fta);
  if (invoice.isTaxTotal || invoice.isTaxDeducted) {
    taxLabel = `${taxLabel} (${invoice.setting.taxRate}%)`;
  }

  let discountLabel = fta(messages.discount);
  if (invoice.isDiscountPercent) {
    discountLabel = `${discountLabel} (${universalInvoice.setting.discountRate}%)`;
  }

  return (
    <div className={'invoice-totals-container content-block'}>
      <SummaryRow
        label={fta(messages.subtotal)}
        className="invoice-summary-subtotal"
        content={<CurrencyDisplay value={+getSubtotal(universalInvoice)} />}
      />

      {universalInvoice.setting.discountType !== DiscountTypes.DISCOUNT_PER_ITEM &&
        universalInvoice.setting.discountType !== DiscountTypes.DISCOUNT_NONE && (
          <SummaryRow
            label={discountLabel}
            className="invoice-summary-discount"
            content={<CurrencyDisplay value={-1 * +getDiscount(universalInvoice)} />}
          />
        )}

      {universalInvoice.setting.taxType !== TaxTypes.TAX_NONE && (
        <SummaryTax invoice={universalInvoice} taxLabel={taxLabel} />
      )}

      {universalInvoice.meta.docType !== DocTypes.DOCTYPE_ESTIMATE && (
        <SummaryRow
          data-selector="invoice-total"
          label={fta(messages.total)}
          content={<CurrencyDisplay value={+getInvoiceTotal(universalInvoice)} />}
        />
      )}

      {universalInvoice.meta.docType !== DocTypes.DOCTYPE_ESTIMATE &&
        getPaidAmount(universalInvoice).gt(0) && (
          <SummaryRow
            label={fta(messages.payments)}
            className="invoice-summary-payments"
            content={<CurrencyDisplay value={-1 * +getPaidAmount(universalInvoice)} />}
          />
        )}

      <SummaryRow
        bold
        label={totalLabel}
        className="invoice-summary-balance invoice-balance"
        data-selector="invoice-balance"
        content={<CurrencyDisplay value={+getInvoiceBalance(universalInvoice)} />}
      />
    </div>
  );
};

export default injectStore(TotalAmounts);
