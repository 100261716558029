import React from 'react';

import { defineMessages, injectIntl } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';
import { compose } from 'src/util/functions';
import injectStore from 'src/util/injectStore';
import { isMigratedSettingsEnabled } from 'src/util/isMigratedSettingsEnabled';
import { BadgePremium } from './BadgePremium';
import NavItem from './NavItem';
import { changeLocationTo } from './utils';

const messages = defineMessages({
  login: {
    id: 'navbar.navAccessItems.login',
    defaultMessage: 'Login'
  },
  logout: {
    id: 'navbar.navAccessItems.logout',
    defaultMessage: 'Logout'
  },
  signup: {
    id: 'navbar.navAccessItems.signup',
    defaultMessage: 'Sign Up'
  },
  loggingOut: {
    id: 'navbar.navItems.loggingOut',
    defaultMessage: 'Logging out...'
  },
  settings: {
    id: 'navbar.navItems.settings',
    defaultMessage: 'Settings'
  },
  upgrade: {
    id: 'navbar.navItems.upgrade',
    defaultMessage: 'Upgrade Now'
  }
});

export const NavAccessItems = (props: { store: AppStore; intl: ISIntl }) => {
  const { store, ...rest } = props;
  const { user, location } = store;
  const isDisabled = user.isLoggingOut;

  if (user.isLoggingOut) {
    return (
      <nav className="nav-access-items" {...rest}>
        <div id="logout" {...rest}>
          {props.intl.formatMessage(messages.loggingOut)}
        </div>
      </nav>
    );
  }

  if (user.isGuest) {
    return (
      <nav className="nav-access-items" {...rest}>
        <NavItem
          href="/settings"
          isActive={location.isSetting}
          isDisabled={isDisabled}
          onClick={(e) => {
            if (isMigratedSettingsEnabled(user.countryCode)) {
              window.location.assign('/settings');
              return;
            }

            changeLocationTo(store.nav, 'settingList')(e);
          }}>
          {props.intl.formatMessage(messages.settings)}
        </NavItem>
        <NavItem
          href="/login"
          customClasses="login-link mr-0"
          onClick={changeLocationTo(props.store.nav, 'login')}
          {...rest}>
          {props.intl.formatMessage(messages.login)}
        </NavItem>
        <span className="divider mx-0">|</span>
        <NavItem
          id="signup-button"
          href="/signup"
          customClasses="signup-link ml-0"
          onClick={changeLocationTo(props.store.nav, 'signup')}>
          {props.intl.formatMessage(messages.signup)}
        </NavItem>
        {user.shouldShowNavUpgradeBtn && (
          <span className="w-sm-100 wrapper d-flex align-items-center ml-lg-4">
            <NavItem
              href="/subscription"
              className="btn btn-light d-flex align-items-center upgrade-now"
              onClick={changeLocationTo(props.store.nav, 'subscription')}
              {...rest}>
              {props.intl.formatMessage(messages.upgrade)}
            </NavItem>
          </span>
        )}
      </nav>
    );
  }

  return (
    <nav className="nav-access-items signed-in-user" {...rest}>
      {user.isSubPaid && (
        <BadgePremium
          className={'d-none d-lg-block'}
          onClick={changeLocationTo(props.store.nav, 'subscription')}
        />
      )}
      <NavItem
        href="/settings"
        isActive={location.isSetting}
        isDisabled={isDisabled}
        onClick={(e) => {
          if (isMigratedSettingsEnabled(user.countryCode)) {
            window.location.assign('/settings');
            return;
          }

          changeLocationTo(store.nav, 'settingList')(e);
        }}>
        {props.intl.formatMessage(messages.settings)}
      </NavItem>
      <NavItem
        href="/logout"
        isDisabled={isDisabled}
        onClick={changeLocationTo(props.store.logout, '')}
        {...rest}>
        {props.intl.formatMessage(messages.logout)}
      </NavItem>
      {user.shouldShowNavUpgradeBtn && (
        <NavItem
          href="/subscription"
          className="btn btn-light align-items-center ml-md-3 upgrade-now"
          onClick={changeLocationTo(props.store.nav, 'subscription')}
          {...rest}>
          {props.intl.formatMessage(messages.upgrade)}
        </NavItem>
      )}
    </nav>
  );
};

export default compose(injectIntl, injectStore)(NavAccessItems);
