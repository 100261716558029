import {
  automaticallyShowInAppMessages,
  getUser,
  InitializationOptions,
  initialize
} from '@braze/web-sdk';

import Rollbar from 'src/analytics/rollbar';

type BrazeOptions = Partial<InitializationOptions>;
type BrazeCustomAttributes = Partial<Record<UserAttributes, any>>;

export enum UserAttributes {
  SIGNED_UP_DATE = 'signed_up',
  IS_GUEST = 'is_guest',
  PAYMENT_STATUS = 'payment_status',
  COMPANY_NAME = 'company_name',
  WEB_ONBOARDING_V1_START = 'web_onboarding_v1_start',
  IS_SUBCRIPTION_ACTIVE = 'is_subscription_active',
  HAS_FREE_TRIAL_SUBSCRIPTION = 'has_free_trial_subscription'
}

export const init = (options?: BrazeOptions) => {
  const apiKey = process.env.REACT_APP_BRAZE_SDK_KEY;
  const baseUrl = process.env.REACT_APP_BRAZE_SDK_BASE_URL;

  try {
    if (!baseUrl || !apiKey) {
      throw new Error('BRAZE_SDK_KEY or BRAZE_SDK_BASE_URL environment is missing');
    }

    const initialized = initialize(apiKey, {
      baseUrl,
      ...options
    });

    if (!initialized) {
      throw new Error('Failed to initialize Braze');
    }

    automaticallyShowInAppMessages();
  } catch (error) {
    const message =
      error instanceof Error
        ? error.message
        : 'Unexpected error while initializing connection with Braze';

    Rollbar.trackError(message, error, { apiKey, baseUrl, options });
  }
};

export const updateBrazeCustomAttributes = (attributes: BrazeCustomAttributes) => {
  try {
    const user = getUser();
    if (!user) {
      return;
    }

    for (const [key, value] of Object.entries(attributes)) {
      user.setCustomUserAttribute(key, value);
    }
  } catch (error) {
    // getUser() is supposed to return undefined if Braze has not been initialized, but it actually throws an error
    return;
  }
};
