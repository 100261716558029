import React, { Fragment } from 'react';

import { useFlags } from 'flagsmith/react';

import { SubscriptionTier } from '@invoice-simple/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISIntl } from 'src/i18n';
import UserModel, { Cadence } from 'src/models/UserModel';
import { useDepositFeatureGate } from 'src/payments/components/PaymentDeposit';
import { AppStore } from 'src/stores/AppStore';
import { LoadingSpinner } from '../../Button';
import { useUpgrade } from '../hooks/useUpgrade';
import {
  getIntervalText,
  getPaywallVariant,
  isFreeTierPaywallEnabled,
  orderedTiers
} from '../utils';
import { messages } from '../utils/messages';
import { DiscountMobilePrice } from './DiscountMobilePrice';

type Feature = 'Core' | 'Collaboration' | 'Views' | 'Invoicing' | 'Support';

type FeatureTier = {
  [tier in SubscriptionTier]?: boolean | string;
};

type FeaturesMap = {
  name: string;
  tiers: FeatureTier;
};

type FeatureStatus = {
  [feature in Feature]?: boolean;
};

interface SectionsMap {
  name: string;
  features: FeaturesMap[];
  comingSoon?: boolean;
  hide?: boolean;
}

interface TieredMatrixV2Props {
  store: AppStore;
  intl: ISIntl;
  selectedInterval: Cadence;
  showTabState: [number, React.Dispatch<React.SetStateAction<number>>];
  shouldDisallowSwitch: (destinationTier: SubscriptionTier, user: UserModel) => boolean;
  tierRequired: SubscriptionTier | null;
  hideFeatures?: FeatureStatus;
}

const CheckIcon: React.FC = () => (
  <FontAwesomeIcon className="ml-auto h-6 w-6 text-orange-is" aria-hidden="true" icon="check" />
);

const MinusIcon: React.FC = () => (
  <FontAwesomeIcon className="ml-auto h-6 w-6 text-gray-400" aria-hidden="true" icon="minus" />
);

const MobilePrice: React.FC<
  TieredMatrixV2Props & {
    mobileSelectedTier: SubscriptionTier;
    tierRequired: SubscriptionTier | null;
  }
> = (props) => {
  const { store, intl, selectedInterval, shouldDisallowSwitch, mobileSelectedTier, tierRequired } =
    props;
  const { user } = store;
  const { ft, fta } = intl;
  const { loadingCheckout, switching, onUpgradeClick, OriginalPrice, FinalPrice, isCurrentPlan } =
    useUpgrade({
      store,
      intl,
      selectedInterval,
      shouldDisallowSwitch
    });

  const loading = loadingCheckout || switching;

  const currentTier = user.activeSubscriptionTier;
  const paywallVariant = getPaywallVariant(currentTier, tierRequired);

  const shouldHideCrossGradeTier =
    paywallVariant !== 'default' &&
    user.activeSubscriptionTier === mobileSelectedTier &&
    user.activeSubscriptionCadence !== selectedInterval;

  const shouldHideTier =
    (paywallVariant !== 'default' &&
      tierRequired === 'premium' &&
      currentTier &&
      tierRequired !== mobileSelectedTier &&
      !isCurrentPlan(mobileSelectedTier)) ||
    shouldHideCrossGradeTier;

  const intervalText = getIntervalText(selectedInterval);

  return (
    <div className="mb-8">
      {mobileSelectedTier === SubscriptionTier.PLUS && paywallVariant === 'default' && (
        <span
          style={{
            backgroundColor: 'rgba(69, 94, 222, 0.1)',
            color: 'rgba(69, 94, 222, 1)',
            fontWeight: 700,
            lineHeight: '20px',
            fontSize: 12,
            cursor: 'default',
            fontFamily:
              'Roboto, system-ui, -apple-system, Segoe UI, Ubuntu, Cantarell, Noto Sans, sans-serif'
          }}
          className="inline-block rounded-full py-1 px-4 mx-[25px] uppercase">
          {fta(messages.mostPopular)}
        </span>
      )}
      <p className="mt-4 mx-[25px]">
        <span className="text-5xl font-bold tracking-tight text-gray-900">
          <span className="font-bold">
            {selectedInterval === Cadence.ANNUAL && <OriginalPrice tier={mobileSelectedTier} />}
          </span>
          <FinalPrice tier={mobileSelectedTier} isTieredMatrix={true} />
        </span>{' '}
        <span className="text-2xl font-medium text-gray-500">{intervalText}</span>
      </p>
      <div className="mx-[25px]">
        {!shouldHideTier && (
          <button
            onClick={() => onUpgradeClick(mobileSelectedTier)}
            className={`mt-6 block w-full rounded-md border-none py-3 text-center text-2xl font-semibold ${
              isCurrentPlan(mobileSelectedTier)
                ? 'bg-gray-100 text-gray-900 border border-solid border-gray-200'
                : 'bg-orange-is text-white border border-orange-is border-solid'
            } ${
              loading || shouldDisallowSwitch(mobileSelectedTier, user)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}>
            {loading && <LoadingSpinner loadingTextStyle="text-white" />}
            {isCurrentPlan(mobileSelectedTier)
              ? ft(messages.currentActivePlan)
              : ft(messages.upgrade)}
          </button>
        )}
      </div>
    </div>
  );
};

const DesktopSection: React.FC<{ feature: FeaturesMap }> = ({ feature }) => {
  const renderTiers = isFreeTierPaywallEnabled() ? [...orderedTiers].reverse() : orderedTiers;

  return (
    <tr key={feature.name} className="border-bottom border-gray-200">
      <th className="py-4 px-10 text-left text-lg font-normal text-gray-800" scope="row">
        {feature.name}
      </th>
      {renderTiers.map((tier) => (
        <td key={tier} className="py-4 px-6 text-center">
          {typeof feature.tiers[tier] === 'string' ? (
            <span className="block text-lg text-gray-800">{feature.tiers[tier]}</span>
          ) : (
            <>
              {feature.tiers[tier] === true ? (
                <FontAwesomeIcon
                  className="h-5 w-5 text-orange-is"
                  aria-hidden="true"
                  icon="check"
                />
              ) : (
                <FontAwesomeIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  icon="minus"
                />
              )}
              <span className="sr-only">
                {feature.tiers[tier] === true ? 'Included' : 'Not included'} in {tier}
              </span>
            </>
          )}
        </td>
      ))}
    </tr>
  );
};

const MobileSection: React.FC<{ section: SectionsMap; index: number; isLast: boolean }> = ({
  section,
  index,
  isLast
}) => {
  return (
    <section key={`tier-section-${index}`}>
      <p
        style={{ fontSize: 14, lineHeight: '20px' }}
        className="w-full bg-gray-50 border-gray-200 py-[15px] px-[25px] font-semibold border-solid border-t-[1px] border-x-0 border-b-0">
        {section.name}
      </p>
      {section.features.map((feature, i2) => (
        <div
          key={`tier-feature-${index}-${i2}`}
          className={`my-[15px] border-gray-200 border-solid
          ${i2 === 0 ? 'border-t-0' : 'border-t-[1px]'}
          ${isLast && i2 === section.features.length - 1 ? 'border-b-[1px]' : ''}
          border-x-0 border-b-0`}>
          <p
            className="px-[25px] py-[15px] font-normal"
            style={{ fontSize: 14, lineHeight: '20px' }}>
            {feature.name}
          </p>
          <div className="my-[15px] px-[25px] grid grid-cols-3 gap-10 text-center">
            {orderedTiers.map((tier, i3) => (
              <div className="" key={`tier-feature-${index}-${i2}-${i3}`}>
                {typeof feature.tiers[tier] === 'string' ? (
                  feature.tiers[tier]
                ) : feature.tiers[tier] ? (
                  <CheckIcon />
                ) : (
                  <MinusIcon />
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export const TieredMatrixListV2 = (props: TieredMatrixV2Props) => {
  const { store, intl, showTabState, tierRequired, selectedInterval, hideFeatures } = props;
  const { client_signatures } = useFlags(['client_signatures']);
  const { user } = store;
  const { ft } = intl;
  const [selectedMobileTab] = showTabState;
  const mobileSelectedTier = orderedTiers[selectedMobileTab];
  const isDepositFeatureGateEnabled = useDepositFeatureGate();

  const getFeature = (
    name: string,
    essentials: boolean | string,
    plus: boolean | string,
    premium: boolean | string
  ) => ({
    name,
    tiers: {
      essentials,
      plus,
      premium
    }
  });

  const sections: SectionsMap[] = [
    {
      name: 'Core',
      features: [
        getFeature(
          ft(messages.invoicesPerMonth),
          ft(messages.invoiceMonthlyLimitEssentials, {
            limit: user.tierOneMonthlyQuota
          }),
          ft(messages.invoiceMonthlyLimitEssentials, {
            limit: user.plusMonthlyQuota
          }),
          ft(messages.unlimited)
        ),
        getFeature(
          ft(messages.estimatesPerMonth),
          ft(messages.unlimited),
          ft(messages.unlimited),
          ft(messages.unlimited)
        )
      ],
      hide: hideFeatures?.['Core']
    },
    {
      name: ft(messages.featureCollaboration),
      features: [
        getFeature(ft(messages.featureAnyDevice), true, true, true),
        getFeature(ft(messages.featurePayments), true, true, true),
        getFeature(ft(messages.featurePassTransactionFee), true, true, true),
        isDepositFeatureGateEnabled
          ? getFeature(ft(messages.featureDeposit), false, false, true)
          : getFeature(ft(messages.featureDeposit), true, true, true),
        getFeature(ft(messages.featureQR), true, true, true),
        ...(client_signatures.enabled
          ? [getFeature(ft(messages.featureClientSignature), false, false, true)]
          : []),
        getFeature(ft(messages.featurePremiumTemplates), false, false, true)
      ],
      hide: hideFeatures?.['Collaboration']
    },
    {
      name: ft(messages.featureViews),
      features: [
        getFeature(ft(messages.featureRealTime), true, true, true),
        getFeature(ft(messages.featureAutofill), true, true, true),
        getFeature(ft(messages.featureSummaryReports), false, true, true)
      ],
      hide: hideFeatures?.['Views']
    },
    {
      name: ft(messages.featureInvoicing),
      features: [
        getFeature(ft(messages.featureDueDateReminders), false, true, true),
        getFeature(ft(messages.featureRatings), false, true, true),
        getFeature(ft(messages.featurePhotos), false, true, true)
      ]
    },
    {
      name: ft(messages.support),
      features: [
        getFeature(ft(messages.featureMessageSupport), true, true, true),
        getFeature(ft(messages.featurePrioritySupport), false, false, true)
      ],
      hide: hideFeatures?.['Support']
    }
  ];

  const mobilePriceComponent = user.shouldOfferNewDiscountedTieredPricing ? (
    <DiscountMobilePrice
      store={props.store}
      intl={props.intl}
      tier={mobileSelectedTier}
      selectedInterval={selectedInterval}
    />
  ) : (
    <MobilePrice {...props} mobileSelectedTier={mobileSelectedTier} tierRequired={tierRequired} />
  );

  const filteredLength = sections.filter((section) => !section.hide).length - 1;

  return (
    <div>
      <div className="mx-auto pt-4 pb-16 sm:py-24 lg:px-8">
        {/* md+ */}
        <div className="hidden md:block">
          {isFreeTierPaywallEnabled() && (
            <h3 className="text-center font-semibold tracking-normal text-gray-900 p-8 capitalize text-3xl mb-4">
              {ft(messages.compareAllFeatures)}
            </h3>
          )}
          <table className="h-px w-full table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            {isFreeTierPaywallEnabled() && (
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th></th>
                  {[...orderedTiers].reverse().map((tier) => {
                    return (
                      <th
                        key={tier}
                        className="text-2xl font-semibold capitalize text-gray-900 py-4 px-6 text-center pb-4">
                        {tier}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody className="divide-y divide-gray-200 border-t border-gray-200">
              {sections
                .filter((section) => !section.hide)
                .map((section) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        className="bg-gray-100 border-top border-bottom border-gray-200 py-3 pl-6 text-left text-xl font-medium text-gray-900"
                        colSpan={4}
                        scope="colgroup">
                        {section.name}
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <DesktopSection feature={feature} key={feature.name} />
                    ))}
                  </Fragment>
                ))}
            </tbody>
          </table>
        </div>

        {/* xs to md */}
        <div className="block md:hidden -mx-5 pt-5 rounded-lg">
          {isFreeTierPaywallEnabled() && (
            <>
              <div className="absolute w-full left-0" style={{ borderTop: '1px solid #E5E7EB' }} />
              <h3 className="text-center font-semibold tracking-normal text-gray-900 py-10 capitalize text-3xl">
                {ft(messages.compareAllFeatures)}
              </h3>
              <div className="grid grid-cols-3 text-center gap-10 sticky top-0 bg-white px-[25px] py-4">
                {orderedTiers.map((tier) => (
                  <p key={tier} className="text-2xl font-semibold capitalize text-gray-900">
                    {tier}
                  </p>
                ))}
              </div>
            </>
          )}

          <section key={mobileSelectedTier}>
            {!isFreeTierPaywallEnabled() && mobilePriceComponent}
            <div>
              {sections
                .filter((section) => !section.hide)
                .map((section, index) => (
                  <MobileSection
                    section={section}
                    index={index}
                    key={index}
                    isLast={filteredLength === index}
                  />
                ))}
            </div>
            {!isFreeTierPaywallEnabled() && mobilePriceComponent}
          </section>
        </div>
      </div>
    </div>
  );
};
